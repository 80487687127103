<template>
  <div class="canvas" id="p5Canvas"></div>
  <div id="custom_variables">
    <ToggleSection title="Controls" :visibleAtStart="true">
      <customButton headerText="Download Image" @header-clicked="download" />
      <customDropdown :options="scenarios" v-model:value="selectedScenario" />

      <customButton headerText="Load Scenario" @click="loadScenario" />
    </ToggleSection>
    <ToggleSection
      title="City Variables"
      class="custom_variables"
      :master="true"
      :visibleAtStart="false"
    >
      <variableWatcher
        label="Wave Size"
        v-model:value="waveSize"
        :min="0.5"
        :max="3"
        :stepSize="0.1"
      />
      <variableWatcher
        label="Wave Speed"
        v-model:value="speed"
        :min="0.1"
        :max="3"
        :stepSize="0.1"
      />

      <variableWatcher
        label="Resolution"
        v-model:value="resolution"
        :min="10"
        :max="200"
        :stepSize="1"
      />
    </ToggleSection>
    <ToggleSection
      title="Skyscraper Variables"
      class="custom_variables"
      :master="true"
      :visibleAtStart="false"
    >
      <variableWatcher
        label="Skyscraper Angle"
        v-model:value="angle"
        :min="0"
        :max="90"
        :stepSize="1"
      />
      <variableWatcher
        label="Skyscraper Height"
        v-model:value="heightMultiplier"
        :min="0.1"
        :max="7"
        :stepSize="0.1"
      />
      <variableWatcher
        label="Flatness Cutoff Value"
        v-model:value="flatValue"
        :min="10"
        :max="100"
        :stepSize="1"
      />
      <variableWatcher
        label="Grass Cutoff Value"
        v-model:value="grassValue"
        :min="10"
        :max="100"
        :stepSize="1"
      />
      <variableWatcher
        label="Water Cutoff Value"
        v-model:value="waterValue"
        :min="10"
        :max="100"
        :stepSize="1"
      />
    </ToggleSection>
    <ToggleSection
      title="Image Variables"
      class="custom_variables"
      :master="true"
      :visibleAtStart="false"
    >
      <variableWatcher
        label="Animate Noise"
        v-model:value="animateNoise"
        inputType="boolean"
      />
      <variableWatcher
        label="Line Width"
        v-model:value="lineWidth"
        :min="0"
        :max="5"
        :stepSize="0.1"
      />
      <variableWatcher
        label="Passe Partout Size"
        v-model:value="border"
        :min="10"
        :max="200"
        :stepSize="1"
      />
      <variableWatcher
        label="Line Color"
        v-model:value="lineColor"
        inputType="color-picker"
      />
      <variableWatcher
        label="Roof Color"
        v-model:value="roofColor"
        inputType="color-picker"
      />
      <variableWatcher
        label="Shade Color"
        v-model:value="shadeColor"
        inputType="color-picker"
      />
      <variableWatcher
        label="Bright Side Color"
        v-model:value="brightSide"
        inputType="color-picker"
      />
      <variableWatcher
        label="Land Color"
        v-model:value="grassColor"
        inputType="color-picker"
      />
      <variableWatcher
        label="Water Color"
        v-model:value="waterColor"
        inputType="color-picker"
      />
      <variableWatcher
        label="Background Color"
        v-model:value="backgroundColor"
        inputType="color-picker"
      />
    </ToggleSection>
  </div>
</template>

<script>
import { City } from "./js/City";
import { ref } from "vue";

import variableWatcher from "../varGui/variableWatcher.vue";
import ToggleSection from "../varGui/ToggleSection.vue";
import customButton from "../varGui/customButton.vue";
import customDropdown from "../varGui/customDropdown.vue";

export default {
  name: "HomeView",
  components: {
    variableWatcher,
    ToggleSection,
    customButton,
    customDropdown,
  },
  setup() {
    // Create reactive variables
    const waveSize = ref(3);
    const speed = ref(2);
    const border = ref(100);
    const resolution = ref(20);
    const lineColor = ref("#000000");
    const roofColor = ref("#F3EADF");
    const shadeColor = ref("#96A6BE");
    const brightSide = ref("#ACB8D9");
    const grassColor = ref("#008E8C");
    const waterColor = ref("#394358");
    const backgroundColor = ref("#ffcccc");
    const animateNoise = ref(true);
    const lineWidth = ref(1);
    const heightMultiplier = ref(4);
    const angle = ref(45);
    const flatValue = ref(50);
    const grassValue = ref(45);
    const waterValue = ref(40);

    // Return the reactive variables
    return {
      waveSize,
      speed,
      border,
      resolution,
      lineColor,
      roofColor,
      shadeColor,
      brightSide,
      grassColor,
      waterColor,
      backgroundColor,
      animateNoise,
      lineWidth,
      heightMultiplier,
      angle,
      flatValue,
      grassValue,
      waterValue,
    };
  },
  data() {
    return {
      scenarios: [
        {
          name: "Slow Large",
          waveSize: 2,
          speed: 1,
          border: 100,
          resolution: 14,
          lineWidth: 1,
          lineColor: "#000000",
          roofColor: "#F3EADF",
          shadeColor: "#96A6BE",
          brightSide: "#ACB8D9",
          grassColor: "#008E8C",
          waterColor: "#394358",
          backgroundColor: "#ffcccc",
          heightMultiplier: 4,
          angle: 45,
          flatValue: 50,
          grassValue: 45,
          waterValue: 40,
        },
        {
          name: "Nighttime",
          waveSize: 2.6,
          speed: 0.5,
          border: 150,
          resolution: 17,
          lineWidth: 1,
          lineColor: "#FFFFFF",
          roofColor: "#000000",
          shadeColor: "#000000",
          brightSide: "#000000",
          grassColor: "#000000",
          waterColor: "#000000",
          backgroundColor: "#000000",
          heightMultiplier: 3,
          angle: 45,
          flatValue: 60,
          grassValue: 50,
          waterValue: 45,
        },
        {
          name: "Neon",
          waveSize: 2.5,
          speed: 2,
          border: 80,
          resolution: 40,
          lineWidth: 1.5,
          lineColor: "#FF00FF",
          roofColor: "#800080",
          shadeColor: "#FF00FF",
          brightSide: "#FF69B4",
          grassColor: "#00FF00",
          waterColor: "#00FFFF",
          backgroundColor: "#000000",
          heightMultiplier: 5,
          angle: 60,
          flatValue: 70,
          grassValue: 60,
          waterValue: 55,
        },
        {
          name: "Rainbow",
          waveSize: 3,
          speed: 1.5,
          border: 120,
          resolution: 20,
          lineWidth: 2,
          lineColor: "#FF0000",
          roofColor: "#FF7F00",
          shadeColor: "#FFFF00",
          brightSide: "#00FF00",
          grassColor: "#0000FF",
          waterColor: "#4B0082",
          backgroundColor: "#9400D3",
          heightMultiplier: 6,
          angle: 75,
          flatValue: 45,
          grassValue: 37,
          waterValue: 27,
        },
      ],
      selectedScenario: null,
    };
  },
  mounted() {
    let width = document.getElementById("p5Canvas").clientWidth;
    let height = document.getElementById("p5Canvas").clientHeight;

    this.city = new City(width, height);

    const script = function (p5) {
      let canvasLength = width;
      this.city.setup(p5);

      // NOTE: Set up is here
      p5.setup = () => {
        let canvas = p5.createCanvas(canvasLength, canvasLength);
        canvas.parent("p5Canvas");

        this.city.reset();
      };
      // NOTE: Draw is here
      p5.draw = () => {
        this.city.draw();
      };
    };
    // NOTE: Use p5 as an instance mode
    const P5 = require("p5");
    new P5(script.bind(this));
  },
  watch: {
    waveSize(value) {
      this.city.settings.waveSize = value;
      this.$forceUpdate(); // Force a re-render
    },
    speed(value) {
      this.city.settings.speed = value;
      this.$forceUpdate(); // Force a re-render
    },
    border(value) {
      this.city.settings.border = value;
      this.resetCity();
      this.$forceUpdate(); // Force a re-render
    },
    resolution(value) {
      this.city.settings.resolution = value;
      this.resetCity();
      this.$forceUpdate(); // Force a re-render
    },
    lineColor(value) {
      this.city.settings.lineColor = value;
      this.resetCity();
      this.$forceUpdate(); // Force a re-render
    },
    roofColor(value) {
      this.city.settings.roofColor = value;
      this.resetCity();
      this.$forceUpdate(); // Force a re-render
    },
    shadeColor(value) {
      this.city.settings.shadeColor = value;
      this.resetCity();
      this.$forceUpdate(); // Force a re-render
    },
    brightSide(value) {
      this.city.settings.brightSide = value;
      this.resetCity();
      this.$forceUpdate(); // Force a re-render
    },
    grassColor(value) {
      this.city.settings.grassColor = value;
      this.resetCity();
      this.$forceUpdate(); // Force a re-render
    },
    waterColor(value) {
      this.city.settings.waterColor = value;
      this.resetCity();
      this.$forceUpdate(); // Force a re-render
    },
    backgroundColor(value) {
      this.city.settings.backgroundColor = value;
      this.resetCity();
      this.$forceUpdate(); // Force a re-render
    },
    animateNoise(value) {
      this.city.settings.animate = value;
      // this.resetCity();
      this.$forceUpdate(); // Force a re-render
    },
    lineWidth(value) {
      this.city.settings.lineWidth = value;
      this.resetCity();
      this.$forceUpdate(); // Force a re-render
    },
    angle(value) {
      this.city.settings.angle = value;
      this.resetCity();
      this.$forceUpdate(); // Force a re-render
    },
    heightMultiplier(value) {
      this.city.settings.heightMultiplier = value;
      this.resetCity();
      this.$forceUpdate(); // Force a re-render
    },
    flatValue(value) {
      this.city.settings.flatValue = value;
      this.resetCity();
      this.$forceUpdate(); // Force a re-render
    },
    grassValue(value) {
      this.city.settings.grassValue = value;
      this.resetCity();
      this.$forceUpdate(); // Force a re-render
    },
    waterValue(value) {
      this.city.settings.waterValue = value;
      this.resetCity();
      this.$forceUpdate(); // Force a re-render
    },
  },
  methods: {
    loadScenario() {
      if (this.selectedScenario) {
        const scenario = this.selectedScenario;
        for (const prop in scenario) {
          if (Object.prototype.hasOwnProperty.call(scenario, prop)) {
            this[prop] = scenario[prop];
          }
        }
        this.$nextTick(() => {
          this.resetCity();
          this.$forceUpdate();
        });
      }
    },
    download() {
      this.city.downloadHD();
    },
    resetCity() {
      this.city.reset();
    },
  },
};
</script>
<style>
#p5Canvas {
  width: 85vh;
  height: 85vh;
  margin-top: 30px;
  margin-left: auto;
  margin-right: auto;
}
</style>
