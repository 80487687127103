import SimplexNoise from "simplex-noise";
import Boid from "./boid";

export class FlowField {
  constructor(canvas, width, height, document) {
    this.draw = canvas;
    this.width = width;
    this.height = height;
    this.document = document;

    this.settings = {};
    this.settings.canvas = { width: this.width, height: this.height };
    this.settings.arraySize = { x: 500, y: 500 };
    this.settings.noise = { size: 3 };
    this.noOfVectors = 30;

    this.settings.showFlowField = false;

    this.settings.gridVisuals = {
      lineColor: "#ffb4dd",
      backgroundColor: "#171717",
      lineTransparancy: 20,
      lineThickness: 1,
      artMode: true,
      animateLint: true,
    };

    this.settings.boids = {
      steeringForce: 1.8,
      numberOfBoids: 500,
    };

    this.simplex = new SimplexNoise();
    this.dataArray = [];

    this.createDataArray();
    this.updateDataArray();

    this.cellHeight = 1;
    this.cellWidth = 1;

    this.boids = [];

    this.p5 = null;
    this.vectorColor = "#1b1b1b";

    this.mouseClicked = false;

    this.boidsCreated = false;
    this.stepsTaken = 0;
    this.maxSteps = 1000;

    this.noiseYSeed = 1;
    this.noiseYSeedIncrease = 1.01;

    this.settings.boidWrap = "respawnRandom";
  }

  setup(p5) {
    this.p5 = p5;
    // this.p5.pixelDensity(8);
  }

  reset() {
    this.createDataArray();
    this.updateDataArray();
    this.boids = [];
    this.p5.background(this.settings.gridVisuals.backgroundColor);
    this.boidsCreated = false;
    this.stepsTaken = 0;
    if (this.settings.showFlowField) {
      this.drawVectors();
    }
    // update the dataArray
  }

  resetNoise() {
    this.noiseYSeed += this.noiseYSeedIncrease;
    this.reset();
  }
  step() {
    if (
      this.settings.arraySize.x != this.dataArray.length ||
      this.settings.arraySize.y != this.dataArray[0].length
    ) {
      this.reset();
    }

    if (!this.boidsCreated) {
      if (this.settings.gridVisuals.artMode) {
        this.createBoids(this.settings.boids.numberOfBoids);
      }
      this.boidsCreated = true;
    }
  }

  drawP5() {
    if (this.stepsTaken < this.maxSteps) {
      this.stepsTaken++;
      if (!this.settings.gridVisuals.artMode) {
        this.p5.background(this.settings.gridVisuals.backgroundColor);
        this.drawGrid();
        this.drawVectors();
      } else {
        this.mouseClicked = false;
      }

      this.boids.forEach((boid) => {
        boid.drawSelf();
      });

      // Check for mouse click and create a Boid if it hasn't been created yet
      if (this.p5.mouseIsPressed && !this.mouseClicked) {
        this.createBoid();
        // Set the flag to true to indicate that a Boid has been created
        this.mouseClicked = true;
      } else if (!this.p5.mouseIsPressed) {
        // Reset the flag when the mouse is released
        this.mouseClicked = false;
      }
    }
  }

  createDataArray() {
    var xLen = this.settings.arraySize.x;
    var yLen = this.settings.arraySize.y;

    this.dataArray = [];
    for (var x = 0; x < xLen; x++) {
      this.dataArray.push([]);
      for (var y = 0; y < yLen; y++) {
        this.dataArray[x].push(0);
      }
    }
  }

  updateDataArray() {
    var xLen = this.settings.arraySize.x;
    var yLen = this.settings.arraySize.y;

    this.cellWidth = this.settings.canvas.width / xLen;
    this.cellHeight = this.settings.canvas.height / yLen;

    for (var x = 0; x < xLen; x++) {
      for (var y = 0; y < yLen; y++) {
        var noiseX =
          ((x * this.cellWidth - this.settings.canvas.width / 2) *
            this.settings.noise.size) /
          1000;
        var noiseY =
          ((y * this.cellHeight - this.settings.canvas.height / 2) *
            this.settings.noise.size) /
          1000;

        var value = this.simplex.noise3D(noiseX, noiseY, this.noiseYSeed);
        this.dataArray[x][y] = value * Math.PI;
      }
    }
  }

  drawGrid() {
    var gridColor = this.p5.color(200, 200, 200);

    for (var i = 0; i < this.settings.arraySize.y + 1; i++) {
      this.p5.stroke(gridColor);
      this.p5.strokeWeight(1);
      this.p5.line(
        0,
        i * this.cellHeight,
        this.settings.canvas.width,
        i * this.cellHeight
      );
    }

    for (var j = 0; j < this.settings.arraySize.x + 1; j++) {
      this.p5.stroke(gridColor);
      this.p5.strokeWeight(1);
      this.p5.line(
        j * this.cellWidth,
        0,
        j * this.cellWidth,
        this.settings.canvas.height
      );
    }
  }

  drawArrowHead(x, y, direction) {
    this.p5.push();
    this.p5.fill(this.vectorColor);
    this.p5.translate(x, y);
    this.p5.rotate(direction);
    this.p5.triangle(0, 1, 3, 0, 0, -1);
    this.p5.pop();
  }

  drawVectors() {
    var xLen = this.noOfVectors;
    var yLen = this.noOfVectors;

    var xIncrement = this.width / xLen;
    var yIncrement = this.height / yLen;

    for (var x = 0; x < this.width; x += xIncrement) {
      for (var y = 0; y < this.height; y += yIncrement) {
        // var x = this.cellWidth * 0.5 + this.cellWidth * x;
        // var centerY = this.cellHeight * 0.5 + this.cellHeight * y;

        var angle = this.lookUpVector(x, y);
        var length = this.width / this.noOfVectors / 2;

        var x2 = x + Math.cos(angle) * length;
        var y2 = y + Math.sin(angle) * length;

        // this.draw
        //   .line(centerX, centerY, x2, y2)
        //   .stroke({ color: "#000", width: 1 });

        this.p5.stroke(this.vectorColor);
        this.p5.strokeWeight(1);
        this.p5.line(x, y, x2, y2);

        this.drawArrowHead(x2, y2, angle);
      }
    }
  }

  drawFlowField() {
    // this.drawGrid();
    this.drawVectors();
  }

  createBoid() {
    var x = this.p5.mouseX;
    var y = this.p5.mouseY;

    if (x > 0 && x < this.width && y > 0 && y < this.height) {
      this.boids.push(new Boid(x, y, this, this.p5));
    }
  }

  createBoids(number) {
    for (var i = 0; i < number; i++) {
      var boid = new Boid(100, 100, this, this.p5);
      // boid.getRandomPosition();
      this.boids.push(boid);
    }
  }

  lookUpVector(x, y) {
    if (x < 0) {
      x = 0;
    }
    if (x >= this.settings.canvas.width) {
      x = this.settings.canvas.width - 1;
    }
    if (y < 0) {
      y = 0;
    }
    if (y >= this.settings.canvas.height) {
      y = this.settings.canvas.height - 1;
    }

    return this.dataArray[Math.floor(x / this.cellWidth)][
      Math.floor(y / this.cellHeight)
    ];
  }

  download() {
    var text = this.draw.svg();
    var fileType = "image/svg+xml";
    var fileName = "myCSV.svg";

    var blob = new Blob([text], { type: fileType });

    var a = document.createElement("a");
    a.download = fileName;
    a.href = URL.createObjectURL(blob);
    a.dataset.downloadurl = [fileType, a.download, a.href].join(":");
    a.style.display = "none";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    setTimeout(function () {
      URL.revokeObjectURL(a.href);
    }, 1500);
  }

  downloadPNG() {
    this.p5.saveCanvas("canvas_image", "png");
  }
}
