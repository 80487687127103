export class DataPoint {
  constructor(settings, x, y) {
    this.settings = settings;
    this.margin = this.settings.gridVisuals.marign;
    this.x = x;
    this.y = y;
    this.z = 0;
    this.value = 0;

    this.getZvalue();

    this.intersections = [];

    this.closestIntersection = { point: { isoX: 0, isoY: 0 }, type: "none" };
    this.furthestIntersection = { point: { isoX: 0, isoY: 0 }, type: "none" };

    this.intersection = { x: 10000, y: 10000 };

    this.stepsX = this.settings.arraySize.x - 1;
    this.stepsY = this.settings.arraySize.y - 1;

    this.width =
      this.settings.canvas.width * (this.settings.arraySize.width / 100);
    this.height =
      this.settings.canvas.height * (this.settings.arraySize.height / 100);

    this.shadow;

    this.baseHeigth =
      this.settings.base.height *
      Math.sin(this.degrees_to_radians(this.settings.camera.angle));
    this.amplifier = this.settings.noise.amplifier;

    this.flatVision = this.settings.camera.angle;

    this.stepSizeX = this.width / this.stepsX;
    this.stepSizeY = this.height / this.stepsY;

    this.outOfSight = false;

    this.isoCorArray = [this.x * this.stepSizeX, this.y * this.stepSizeY];

    this.updateValue(this.value);

    this.isoCorBase = this.cartToIso(this.isoCorArray, 0);

    this.isoX = this.isoCor[0];
    this.isoY = this.isoCor[1];

    this.isoBaseX = this.isoCorBase[0];
    this.isoBaseY = this.isoCorBase[1];

    this.getZvalue();

    this.isoGroundBase = this.cartToIso(this.isoCorArray, -this.baseHeigth);
    this.floatingGround = this.cartToIso(this.isoCorArray, -this.baseHeigth);
  }

  addIntersection(intersectionPoint, typeOfIntersection) {
    this.intersections.push({
      point: intersectionPoint,
      type: typeOfIntersection,
    });
  }

  clearCollisions() {
    this.intersections = [];
  }

  updateIntersections() {
    if (this.intersections.length == 1) {
      this.closestIntersection = this.intersections[0];
      this.furthestIntersection = this.intersections[0];
    } else {
      var closestDistance = 10000000;
      var furthestDistance = -100;

      this.intersections.forEach(function (intersection) {
        var currentDistance = this.getDistance(this, intersection.point);
        if (currentDistance > furthestDistance) {
          furthestDistance = currentDistance;
          this.furthestIntersection = intersection;
        }

        if (currentDistance < closestDistance) {
          closestDistance = currentDistance;
          this.closestIntersection = intersection;
        }
      }, this);
    }
  }

  getDistance(pointA, pointB) {
    var Ax = pointA.isoX;
    var Ay = pointA.isoY;
    var Bx = pointB.isoX;
    var By = pointB.isoY;

    var dist = Math.sqrt(Math.pow(Ax - Bx, 2) + Math.pow(Ay - By, 2));

    return dist;
  }

  setOutOfSight() {
    this.outOfSight = true;
  }

  setInSight() {
    this.outOfSight = false;
  }

  checkVisibility() {
    return !this.outOfSight;
  }

  shadowOf(point) {
    this.shadow = point;
  }

  setIntersectionCor(pointX, pointY) {
    if (this.intersection.y > pointY) {
      this.intersection = { x: pointX, y: pointY };
    }
  }

  updateValue(value) {
    this.baseHeigth =
      this.settings.base.height *
      Math.cos(this.degrees_to_radians(this.settings.camera.angle));
    this.outOfSight = false;

    this.value = value;

    this.isoCor = this.cartToIso(
      this.isoCorArray,
      value *
        this.settings.noise.amplifier *
        Math.cos(this.degrees_to_radians(this.settings.camera.angle))
    );

    this.isoGround = this.cartToIso(
      this.isoCorArray,
      value *
        this.settings.noise.amplifier *
        Math.cos(this.degrees_to_radians(this.settings.camera.angle)) -
        this.baseHeigth
    );

    this.isoX = this.isoCor[0];
    this.isoY = this.isoCor[1];

    this.floatGroundX = this.isoCor[0];
    this.floatingGroundY = this.isoCor[1] + this.baseHeigth;

    if (!this.settings.base.straigthBase) {
      this.isoGroundBase = this.isoGround;
    }
  }

  getZvalue() {
    this.z = this.x + this.y;
  }

  updateVariables() {
    if (!this.debug) {
      this.stepsX = this.settings.arraySize.x;
      this.stepsY = this.settings.arraySize.y;
    }

    this.width = this.settings.canvas.width;
    this.heigth = this.settings.canvas.height;

    this.baseHeigth = this.settings.base.height;

    this.amplifier = this.settings.noise.amplifier;

    this.flatVision = this.settings.camera.angle;
  }

  updateCoordinates() {}

  cartToIso([screenX, screenY], pointHeight) {
    screenY -= this.settings.canvas.height / 2;
    screenX -= this.settings.canvas.width / 2;

    this.flatVision = Math.sin(
      this.degrees_to_radians(this.settings.camera.angle)
    );

    var tileWidthHalf = 1 / (this.settings.arraySize.width / 100);
    var tileHeightHalf = 1 / (this.settings.arraySize.height / 100);
    var isoX =
      0.6 *
        this.flatVision *
        (screenX / tileWidthHalf + screenY / tileHeightHalf) -
      pointHeight;
    var isoY = 0.6 * (-screenX / tileWidthHalf + screenY / tileHeightHalf);
    return [
      isoY + this.settings.canvas.width / 2,
      isoX + this.settings.canvas.height / 2,
    ];
  }

  degrees_to_radians(degrees) {
    var pi = Math.PI;
    return degrees * (pi / 180);
  }
}
