<template>
  <div
    class="header"
    @click="triggerAction"
    @mousedown="buttonPressed = true"
    @mouseup="buttonPressed = false"
    @mouseleave="
      buttonPressed = false;
      triggerAction;
    "
    :class="{ pressed: buttonPressed }"
  >
    {{ headerText }}
  </div>
</template>

<script>
export default {
  props: {
    headerText: String,
  },
  data() {
    return {
      buttonPressed: false,
    };
  },
  methods: {
    triggerAction() {
      this.$emit("header-clicked");
    },
  },
};
</script>

<style scoped>
.header {
  cursor: pointer;
  font-size: 0.8rem;
  /* font-weight: bold; */
  margin: 5px;
  border-radius: var(--border-radius-small);
  border: 1px solid var(--line-color);
  padding: 5px 10px;
  transition: transform 0.1s; /* Added transition */
}

.header:hover {
  transform: scale(1.02);
}

.pressed {
  transform: translateY(2px); /* Added translateY */
  background-color: var(--button-background);
  transform: scale(0.82);
}
</style>
