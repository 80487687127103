<template>
  <div @click="handleMouseClick">
    <div class="canvas" id="p5Canvas" ref="canvas"></div>
    <!-- <div class="canvas" id="canvas" ref="canvas"></div> -->
  </div>
  <div id="custom_variables">
    <ToggleSection title="Controls" :visibleAtStart="true">
      <customButton
        headerText="Download Vector File"
        @header-clicked="download"
      />
      <customButton headerText="New Noise" @header-clicked="newNoise" />
    </ToggleSection>

    <ToggleSection
      title="Variables"
      class="custom_variables"
      :master="true"
      :visibleAtStart="true"
    >
      <ToggleSection title="Grid">
        <variableWatcher
          label="X Count"
          v-model:value="arraySizeX"
          :min="5"
          :max="1000"
          :stepSize="1"
        />
        <variableWatcher
          label="Y Count"
          v-model:value="arraySizeY"
          :min="5"
          :max="1000"
          :stepSize="1"
        />
      </ToggleSection>
      <ToggleSection title="Noise">
        <variableWatcher
          label="Noise Size"
          v-model:value="noiseSize"
          :min="0.1"
          :max="5"
          :stepSize="0.01"
        />
        <variableWatcher
          label="Draw Flow Field"
          v-model:value="showFlowField"
          inputType="boolean"
        />
      </ToggleSection>
      <ToggleSection title="Lint Setting">
        <customDropdown
          :options="lintCrossingOptions"
          v-model:value="selectedLintCrossingOption"
          label="Lint that crosses the edge.."
        />
        <variableWatcher
          label="Number of Lints"
          v-model:value="numberOfLints"
          :min="0"
          :max="5000"
          :stepSize="1"
        />
        <variableWatcher
          label="Growth Duration"
          v-model:value="duration"
          :min="20"
          :max="8000"
          :stepSize="10"
        />
        <variableWatcher
          label="Lint Alignment"
          v-model:value="boidSteeringForce"
          :min="0.3"
          :max="2"
          :stepSize="0.01"
        />
        <variableWatcher
          label="Background Color"
          v-model:value="backgroundColor"
          v-model="backgroundColor"
          inputType="color-picker"
        />
        <variableWatcher
          label="Lint Color"
          v-model:value="lintColor"
          v-model="lintColor"
          inputType="color-picker"
        />
        <variableWatcher
          label="Lint Transparancy"
          v-model:value="lintTransparancy"
          :min="5"
          :max="255"
          :stepSize="1"
        />
        <variableWatcher
          label="Lint Thickness"
          v-model:value="lintThickness"
          :min="0.5"
          :max="15"
          :stepSize="0.1"
        />

        <variableWatcher
          label="Animate Lint"
          v-model:value="animateLint"
          inputType="boolean"
        />
      </ToggleSection>
    </ToggleSection>
    <ToggleSection title="Scene Settings">
      <variableWatcher
        label="Grid Line Color"
        v-model:value="gridLineColor"
        v-model="gridLineColor"
        inputType="color-picker"
      />
      <variableWatcher
        label="Background Color"
        v-model:value="gridBackgroundColor"
        v-model="gridBackgroundColor"
        inputType="color-picker"
      />
      <variableWatcher
        label="Line Thickness"
        v-model:value="gridLineWidth"
        :min="0.1"
        :max="5"
        :stepSize="0.1"
      />
    </ToggleSection>
  </div>
</template>

<script>
// import { SVG } from "@svgdotjs/svg.js";
import { FlowField } from "./js/flowfield.js";
import { ref } from "vue";

import variableWatcher from "../varGui/variableWatcher.vue";
import ToggleSection from "../varGui/ToggleSection.vue";
import customButton from "../varGui/customButton.vue";
import customDropdown from "../varGui/customDropdown.vue";

export default {
  name: "ShyCanopies",
  components: {
    variableWatcher,
    ToggleSection,
    customButton,
    customDropdown,
  },
  setup() {
    const arraySizeX = ref(500);
    const arraySizeY = ref(500);
    const noiseSize = ref(3);
    const boidSteeringForce = ref(1.8);
    const duration = ref(1000);
    const numberOfLints = ref(500);
    const backgroundColor = ref("#FFF");
    const lintColor = ref("#000000");
    const lintTransparancy = ref(50);
    const lintThickness = ref(1);
    const showFlowField = ref(false);
    const animateLint = ref(true);
    return {
      arraySizeX,
      arraySizeY,
      noiseSize,
      boidSteeringForce,
      duration,
      numberOfLints,
      backgroundColor,
      lintColor,
      lintTransparancy,
      lintThickness,
      showFlowField,
      animateLint,
    };
  },
  data() {
    return {
      lintCrossingOptions: [
        {
          name: "Respawn random in canvas",
          alias: "respawnRandom",
        },
        {
          name: "Respawn random along edge",
          alias: "respawnEdge",
        },
        {
          name: "Wrap around edge",
          alias: "wrapEdge",
        },
        {
          name: "Don't respawn",
          alias: "dontRespawnStartRandom",
        },
      ],
      selectedLintCrossingOption: null,
    };
  },
  watch: {
    selectedLintCrossingOption(value) {
      this.flowfield.settings.boidWrap = value.alias;
      this.flowfield.reset();
      this.$forceUpdate(); // Force a re-render
    },
    arraySizeX(value) {
      this.flowfield.settings.arraySize.x = value;
      this.$forceUpdate(); // Force a re-render
    },
    arraySizeY(value) {
      this.flowfield.settings.arraySize.y = value;
      this.$forceUpdate(); // Force a re-render
    },
    noiseSize(value) {
      this.flowfield.settings.noise.size = value;
      this.flowfield.reset();
      this.$forceUpdate(); // Force a re-render
    },
    showFlowField(value) {
      this.flowfield.settings.showFlowField = value;
      this.flowfield.reset();
      this.$forceUpdate(); // Force a re-render
    },
    boidSteeringForce(value) {
      this.flowfield.settings.boids.steeringForce = value;
      this.flowfield.reset();
      this.$forceUpdate(); // Force a re-render
    },
    duration(value) {
      this.flowfield.maxSteps = value;
      this.flowfield.reset();
      this.$forceUpdate(); // Force a re-render
    },
    numberOfLints(value) {
      this.flowfield.settings.boids.numberOfBoids = value;
      this.flowfield.reset();
      this.$forceUpdate(); // Force a re-render
    },
    backgroundColor(value) {
      this.flowfield.settings.gridVisuals.backgroundColor = value;
      this.flowfield.reset();
      this.$forceUpdate(); // Force a re-render
    },
    lintColor(value) {
      this.flowfield.settings.gridVisuals.lineColor = value;
      this.flowfield.reset();
      this.$forceUpdate(); // Force a re-render
    },
    lintTransparancy(value) {
      this.flowfield.settings.gridVisuals.lineTransparancy = value;
      this.flowfield.reset();
      this.$forceUpdate(); // Force a re-render
    },
    lintThickness(value) {
      this.flowfield.settings.gridVisuals.lineThickness = value;
      this.flowfield.reset();
      this.$forceUpdate(); // Force a re-render
    },
    animateLint(value) {
      this.flowfield.settings.gridVisuals.animateLint = value;
      this.flowfield.reset();
      this.$forceUpdate(); // Force a re-render
    },
  },
  mounted() {
    const canvasElement = this.$refs.canvas;
    let width = canvasElement.clientWidth;
    let height = canvasElement.clientHeight;

    // const draw = SVG().addTo(canvasElement).size(width, height);

    this.flowfield = new FlowField(null, width, height);

    const script = function (p5) {
      this.flowfield.setup(p5);

      // NOTE: Set up is here
      p5.setup = () => {
        let canvas = p5.createCanvas(width, height);
        canvas.parent("p5Canvas");

        this.flowfield.reset();
      };
      p5.draw = () => {
        this.flowfield.step();
        this.flowfield.drawP5();
      };
    };
    const P5 = require("p5");
    new P5(script.bind(this));
  },
  methods: {
    download() {
      // Do something when header is clicked
      this.flowfield.downloadPNG();
    },
    newNoise() {
      this.flowfield.resetNoise();
    },
  },
};
</script>

<style scoped>
h1 {
  font-size: 1.2rem;
  text-align: left;
}
</style>
