export class VisualArray {
  constructor(canvas, settings) {
    this.draw = canvas;
    this.settings = settings;
    this.array = [];
    this.depth = 0;
    this.color = this.settings.gridVisuals.lineColor;
    this.visualArray = [];
  }

  setDepth(depth) {
    this.depth = depth;
    this.array = [];
    this.visualArray = [];
    for (var i = 0; i < this.depth; i++) {
      this.array.push([]);
      this.visualArray.push([]);
    }
  }

  addPoint(point, z) {
    this.array[z].push(point);
  }

  clear() {
    this.array = [];
    this.visualArray = [];
  }

  drawSelf() {
    var firstArray = this.array;
    var firstLength = firstArray.length;
    var firstLinesLength = firstArray[0].length;

    for (var i = firstLength - 1; i >= 0; i--) {
      var firstLine = firstArray[i];
      this.visualArray[i].push([]);
      var section = 0;

      for (var h = firstLinesLength - 1; h > 0; h--) {
        var firstPoint = firstLine[h];
        var secondPoint = firstLine[h - 1];

        if (
          i == firstLength - 1 &&
          h == firstLinesLength - 1 &&
          this.settings.base.enable
        ) {
          if (this.settings.base.edgeLine) {
            this.visualArray[i][section].push([
              firstLine[0].isoX,
              firstLine[0].isoY,
            ]);
          }
          for (var p = -1; p < firstLinesLength - 1; p++) {
            this.visualArray[i][section].push([
              firstLine[p + 1].isoGroundBase[0],
              firstLine[p + 1].isoGroundBase[1],
            ]);
          }
          section++;
          this.visualArray[i].push([]);

          if (this.settings.base.contours) {
            for (var r = 0; r < this.settings.base.lineCount; r++) {
              for (var q = -1; q < firstLinesLength - 1; q++) {
                var stepSize =
                  (firstLine[q + 1].isoY - firstLine[q + 1].isoGroundBase[1]) /
                  (this.settings.base.lineCount + 1);
                this.visualArray[i][section].push([
                  firstLine[q + 1].isoGroundBase[0],
                  firstLine[q + 1].isoGroundBase[1] + stepSize * (r + 1),
                ]);
              }
              section++;
              this.visualArray[i].push([]);
            }
          }

          if (this.settings.base.edgeLine) {
            this.visualArray[i][section].push([
              firstLine[firstLinesLength - 1].isoX,
              firstLine[firstLinesLength - 1].isoY,
            ]);
            this.visualArray[i][section].push([
              firstLine[firstLinesLength - 1].isoGroundBase[0],
              firstLine[firstLinesLength - 1].isoGroundBase[1],
            ]);
            section++;
            this.visualArray[i].push([]);
          }
          // if (!verticalLine) {
          //   section++;
          //   this.visualArray[i].push([]);
          // }
        }

        firstPoint.updateIntersections();

        var collisions = firstPoint.intersections.length;

        var firstIntersection;
        var lastIntersection;

        if (collisions > 0) {
          firstIntersection = firstPoint.closestIntersection.point;
          lastIntersection = firstPoint.furthestIntersection.point;
        }
        var firstPointVisible = firstPoint.checkVisibility();
        var secondPointVisible = secondPoint.checkVisibility();

        var isLastLine = false;
        if (h == 1) {
          isLastLine = true;
        }

        if (firstPointVisible) {
          if (secondPointVisible) {
            if (collisions == 0) {
              this.visualArray[i][section].push([
                firstPoint.isoX,
                firstPoint.isoY,
              ]);
            }

            if (collisions > 1) {
              this.visualArray[i][section].push([
                firstPoint.isoX,
                firstPoint.isoY,
              ]);

              var visible = true;

              var averagePoint = {
                isoX: (firstPoint.isoX + firstIntersection.isoX) / 2,
                isoY: (firstPoint.isoY + firstIntersection.isoY) / 2,
              };

              for (var j = i; j < firstLength - 1; j++) {
                if (
                  this.pointBelowLine(
                    averagePoint,
                    this.array[j + 1][h],
                    this.array[j][h]
                  )
                ) {
                  visible = false;
                }
              }

              if (visible) {
                this.visualArray[i][section].push([
                  firstIntersection.isoX,
                  firstIntersection.isoY,
                ]);
              }

              section++;
              this.visualArray[i].push([]);

              this.visualArray[i][section].push([
                lastIntersection.isoX,
                lastIntersection.isoY,
              ]);
            }
            if (isLastLine) {
              this.visualArray[i][section].push([
                secondPoint.isoX,
                secondPoint.isoY,
              ]);
            }
          }

          if (!secondPointVisible) {
            if (collisions == 0) {
              this.visualArray[i][section].push([
                firstPoint.isoX,
                firstPoint.isoY,
              ]);
              section++;
              this.visualArray[i].push([]);
            }

            if (collisions > 0) {
              this.visualArray[i][section].push([
                firstPoint.isoX,
                firstPoint.isoY,
              ]);

              visible = true;

              averagePoint = {
                isoX: (firstPoint.isoX + firstIntersection.isoX) / 2,
                isoY: (firstPoint.isoY + firstIntersection.isoY) / 2,
              };

              for (var k = i; k < firstLength - 1; k++) {
                if (
                  this.pointBelowLine(
                    averagePoint,
                    this.array[k + 1][h],
                    this.array[k][h]
                  )
                ) {
                  visible = false;
                }
              }

              if (visible) {
                this.visualArray[i][section].push([
                  firstIntersection.isoX,
                  firstIntersection.isoY,
                ]);
              }

              section++;
              this.visualArray[i].push([]);
            }
          }
        }

        if (!firstPointVisible) {
          if (secondPointVisible) {
            if (collisions == 0) {
              this.visualArray[i][section].push([
                firstPoint.isoX,
                firstPoint.isoY,
              ]);
            }
            if (collisions == 1) {
              this.visualArray[i][section].push([
                lastIntersection.isoX,
                lastIntersection.isoY,
              ]);
            }

            if (collisions > 1) {
              if (lastIntersection.isoX == 0) {
                console.log(firstPoint);
              }

              this.visualArray[i][section].push([
                lastIntersection.isoX,
                lastIntersection.isoY,
              ]);
            }

            if (isLastLine) {
              this.visualArray[i][section].push([
                secondPoint.isoX,
                secondPoint.isoY,
              ]);
            }
          }
        }
      }
    }

    this.visualArray.forEach(function (array) {
      array.forEach(function (subArray) {
        this.draw
          .polyline(subArray)
          .stroke({
            color: this.settings.gridVisuals.lineColor,
            opacity: 1,
            width: this.settings.gridVisuals.width,
          })
          .fill("none");
      }, this);
    }, this);
  }

  checkCollision(other) {
    this.checkCollisionWithArray(other);
  }

  degrees_to_radians(degrees) {
    var pi = Math.PI;
    return degrees * (pi / 180);
  }

  checkCollisionWithArray(checkArray, typeOfLine) {
    // var debugMode = menu.debug.getValue();

    var firstArray = this.array;
    var secondArray = checkArray.array;

    var firstLength = firstArray.length;
    var secondLength = secondArray.length;

    var firstLinesLength = firstArray[0].length;
    var secondLinesLength = secondArray[0].length;

    //Go backwards through the first array (of arrays)

    for (var i = firstLength - 1; i >= 0; i--) {
      var firstLine = firstArray[i];

      for (var h = firstLinesLength - 1; h > 0; h--) {
        var firstLineFirstPoint = firstLine[h];
        var firstLineSecondPoint = firstLine[h - 1];
        var firstLineSegtmentZ = firstLineFirstPoint.z + firstLineSecondPoint.z;

        if (
          !firstLineFirstPoint.checkVisibility() &&
          !firstLineSecondPoint.checkVisibility()
        ) {
          continue;
        }

        for (var j = secondLength - 1; j >= 0; j--) {
          var secondLine = secondArray[j];

          for (var k = secondLinesLength - 1; k > 0; k--) {
            var secondLineFirstPoint = secondLine[k];
            var secondLineSecondPoint = secondLine[k - 1];
            var secondLineSegtmentZ =
              secondLineFirstPoint.z + secondLineSecondPoint.z;

            //Only continue of we have a second line segment with a higher z (closer to the camera)

            //Check that no points are the same on the line segments. This would trigger false intersection
            if (
              firstLineFirstPoint != secondLineFirstPoint &&
              firstLineFirstPoint != secondLineSecondPoint &&
              firstLineSecondPoint != secondLineFirstPoint &&
              firstLineSecondPoint != secondLineSecondPoint &&
              secondLineSegtmentZ > firstLineSegtmentZ
            ) {
              //Check if the lines intersect
              var intersectionPoint = this.intersect(
                firstLineFirstPoint.isoX,
                firstLineFirstPoint.isoY,
                firstLineSecondPoint.isoX,
                firstLineSecondPoint.isoY,
                secondLineFirstPoint.isoX,
                secondLineFirstPoint.isoY,
                secondLineSecondPoint.isoX,
                secondLineSecondPoint.isoY
              );

              if (intersectionPoint == false) {
                // If there is no intersection
                // If the first point is out of sight, the second point will also be if there is no intersections
              } else {
                // If there is a intersection, draw a point

                firstLineFirstPoint.addIntersection(
                  {
                    isoX: intersectionPoint[0],
                    isoY: intersectionPoint[1],
                  },
                  typeOfLine
                );
              }
            }
          }
        }
      }
    }
  }

  checkOutOfSight(checkArray) {
    var firstArray = this.array;
    var secondArray = checkArray.array;

    var firstLength = firstArray.length;
    var secondLength = secondArray.length;

    var firstLinesLength = firstArray[0].length;
    var secondLinesLength = secondArray[0].length;

    //Go backwards through the first array (of arrays)

    for (var i = firstLength - 1; i >= 0; i--) {
      var firstLine = firstArray[i];

      for (var h = firstLinesLength - 1; h > 0; h--) {
        var firstLineFirstPoint = firstLine[h];
        var firstLineSecondPoint = firstLine[h - 1];
        var firstLineSegtmentZ = firstLineFirstPoint.z + firstLineSecondPoint.z;

        for (var j = secondLength - 1; j >= 0; j--) {
          var secondLine = secondArray[j];

          for (var k = secondLinesLength - 1; k > 0; k--) {
            var secondLineFirstPoint = secondLine[k];
            var secondLineSecondPoint = secondLine[k - 1];
            var secondLineSegtmentZ =
              secondLineFirstPoint.z + secondLineSecondPoint.z;

            if (
              firstLineFirstPoint != secondLineFirstPoint &&
              firstLineFirstPoint != secondLineSecondPoint &&
              firstLineSecondPoint != secondLineFirstPoint &&
              firstLineSecondPoint != secondLineSecondPoint &&
              secondLineSegtmentZ > firstLineSegtmentZ
            ) {
              if (
                this.pointBelowLine(
                  firstLineFirstPoint,
                  secondLineFirstPoint,
                  secondLineSecondPoint
                )
              ) {
                firstLineFirstPoint.setOutOfSight();
              }

              // for the last item of the array, also check the second point
              if (h == 1) {
                if (
                  this.pointBelowLine(
                    firstLineSecondPoint,
                    secondLineFirstPoint,
                    secondLineSecondPoint
                  )
                ) {
                  firstLineSecondPoint.setOutOfSight();
                }
              }
            }
          }
        }
      }
    }
  }

  intersect(x1, y1, x2, y2, x3, y3, x4, y4) {
    // Check if none of the lines are of length 0
    if ((x1 === x2 && y1 === y2) || (x3 === x4 && y3 === y4)) {
      return false;
    }

    var denominator = (y4 - y3) * (x2 - x1) - (x4 - x3) * (y2 - y1);

    // Lines are parallel
    if (denominator === 0) {
      return false;
    }

    let ua = ((x4 - x3) * (y1 - y3) - (y4 - y3) * (x1 - x3)) / denominator;
    let ub = ((x2 - x1) * (y1 - y3) - (y2 - y1) * (x1 - x3)) / denominator;

    // is the intersection along the segments
    if (ua < 0 || ua > 1 || ub < 0 || ub > 1) {
      return false;
    }

    // Return a object with the x and y coordinates of the intersection
    let xCor = x1 + ua * (x2 - x1);
    let yCor = y1 + ua * (y2 - y1);

    return [xCor, yCor];
  }

  pointBelowLine(point, lineA, lineB) {
    var Px = point.isoX;
    var Py = point.isoY;
    var Ax = lineA.isoX;
    var Ay = lineA.isoY;
    var Bx = lineB.isoX;
    var By = lineB.isoY;

    // Check to see if the point is outside the line segment
    if (Px < Math.min(Ax, Bx) || Px > Math.max(Ax, Bx)) {
      return false;
    }

    // Find the slope m
    var m = (By - Ay) / (Bx - Ax);

    // Find the y-intercept
    var b = Ay - m * Ax;

    // See if the point is within the range of the line segment
    var lineY = m * Px + b;
    if (lineY < Py) {
      // point below line
      return true;
    } else {
      // point above line
      return false;
    }
  }
}
