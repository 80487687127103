<template>
  <div class="container">
    <div class="header" v-bind:class="{ 'header-when-visible': visible }">
      <i class="pi" :class="iconClass" @click="toggle"></i>
      <div id="title">{{ title }}</div>
    </div>
    <div v-if="visible" v-bind:class="{ master: master }">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    visibleAtStart: { type: Boolean, default: false },
    title: String,
    master: { type: Boolean, default: false },
  },
  data() {
    return {
      visible: this.visibleAtStart,
    };
  },

  computed: {
    iconClass() {
      return this.visible ? "pi-caret-up" : "pi-caret-down";
    },
  },
  methods: {
    toggle() {
      this.visible = !this.visible;
    },
  },
};
</script>

<style scoped>
.container {
  background-color: var(--background-color);
  border: 1px solid;
  border-color: var(--line-color);
  border-radius: var(--border-radius-large);
  margin: 2px;
  margin-left: 7px;
}

.header {
  padding: 1px;
  padding-top: 6px;
  width: 100%;
  display: flex;
  font-weight: bold;
}

.header-when-visible {
  border-bottom: 1px solid var(--line-color);
}
.header i {
  cursor: pointer;
  margin-left: 2px;
  margin-right: 7px;
  font-size: 1rem;
  width: 20px;
  height: 20px;
  border-radius: var(--border-radius-large);

  padding-top: -1px;
  /* border: 1px solid var(--line-color); */
}

.master {
  max-height: 75vh;
  overflow: scroll;
}

#title {
  font-size: 0.8rem;
}
</style>
