<template>
  <div class="header">{{ label }}</div>

  <div class="custom-select" :tabindex="tabindex" @blur="open = false">
    <div class="selected" :class="{ open: open }" @click="open = !open">
      {{ selected.name }}
    </div>
    <div class="items" :class="{ selectHide: !open }">
      <div
        v-for="(option, i) of options"
        :key="i"
        @click="
          selected = option;
          open = false;
          $emit('input', option);
          emitSelected();
        "
      >
        {{ option.name }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    label: String,
    options: {
      type: Array,
      required: true,
    },
    value: {
      // 'value' is the prop used with v-model
      type: Object,
      default: () => {},
    },
    tabindex: {
      type: Number,
      required: false,
      default: 0,
    },
  },
  data() {
    return {
      selected: this.default
        ? this.default
        : this.options.length > 0
        ? this.options[0]
        : null,
      open: false,
    };
  },
  mounted() {
    this.$emit("update:value", this.selected);
  },
  methods: {
    emitSelected() {
      this.$emit("update:value", this.selected);
    },
  },
};
</script>

<style scoped>
.header {
  text-align: left;
  padding-left: 10px;
  font-size: 0.8rem;
}
.custom-select {
  margin: 5px;
  position: relative;
  /* width: 100%; */
  text-align: left;
  outline: none;
  /* line-height: 47px; */
}

.custom-select .selected {
  border-radius: var(--border-radius-small);
  border: 1px solid var(--line-color);
  color: var(--text-color);
  padding: 5px 10px;
  padding-left: 1em;
  cursor: pointer;
  user-select: none;
  font-size: 0.8rem;
}

.custom-select .selected.open {
  border: 1px solid var(--line-color);
  border-radius: var(--border-radius-small);
}

.custom-select .selected:after {
  position: absolute;
  content: "";
  top: 12px;
  right: 1em;
  width: 0;
  height: 0;
  border: 5px solid var(--line-color);
  border-color: var(--line-color) transparent transparent transparent;
}

.custom-select .items {
  color: var(--text-color);
  border-radius: 0px 0px var(--border-radius-small) var(--border-radius-small);
  overflow: hidden;
  position: absolute;
  left: 0;
  right: 0;
  z-index: 1;
  border-bottom: 1px solid var(--line-color);
  border-left: 1px solid var(--line-color);
  border-right: 1px solid var(--line-color);
}

.custom-select .items div {
  font-size: 0.8rem;
  color: var(--text-color);
  background-color: var(--background-color);
  padding-left: 1em;
  cursor: pointer;
  user-select: none;
}

.custom-select .items div:hover {
  background-color: #cecece;
}

.selectHide {
  display: none;
}
</style>
