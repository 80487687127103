<template>
  <div class="canvas" id="canvas" ref="canvas"></div>
  <div id="custom_variables">
    <ToggleSection title="Controls" :visibleAtStart="true">
      <customButton headerText="Regrow Scene" @header-clicked="regrow" />
      <customButton headerText="Redraw Scene" @header-clicked="redraw" />
      <customButton
        headerText="Download Vector File"
        @header-clicked="download"
      />
      <customDropdown
        :options="scenarios"
        v-model:value="selectedScenario"
        label="Select Scenario"
      />

      <customButton headerText="Load Scenario" @click="loadScenario" />
    </ToggleSection>

    <ToggleSection
      title="Variables"
      class="custom_variables"
      :master="true"
      :visibleAtStart="true"
    >
      <ToggleSection title="Branch DNA">
        <variableWatcher
          label="Twig Length"
          v-model:value="twigLength"
          :min="1"
          :max="20"
          :stepSize="0.5"
        />
        <variableWatcher
          label="Deviation Angle (radians)"
          v-model:value="deviationAngle"
          :min="0.8"
          :max="4"
          :stepSize="0.1"
        />
        <variableWatcher
          label="Branch Max Size"
          v-model:value="branchSize"
          :min="10"
          :max="300"
          :stepSize="1"
        />
      </ToggleSection>
      <ToggleSection title="Scene Settings">
        <variableWatcher
          label="Circle Passe Partout"
          v-model:value="circle"
          inputType="boolean"
        />
        <variableWatcher
          label="Single middle seed"
          v-model:value="middleSeed"
          inputType="boolean"
        />
        <variableWatcher
          label="Number of Seeds"
          v-model:value="seeds"
          :min="1"
          :max="40"
        />
        <variableWatcher
          label="Vertical Passe Partout"
          v-model:value="verticalMargin"
          :min="20"
          :max="300"
        />
        <variableWatcher
          label="Horizontal Passe Partout"
          v-model:value="horizontalMargin"
          :min="20"
          :max="300"
        />
        <variableWatcher
          label="Twig Color"
          v-model:value="twigColor"
          v-model="twigColor"
          inputType="color-picker"
        />
        <variableWatcher
          label="Background Color"
          v-model:value="bgColor"
          v-model="bgColor"
          inputType="color-picker"
        />
      </ToggleSection>
    </ToggleSection>
  </div>
</template>

<script>
import { SVG } from "@svgdotjs/svg.js";
import { Canopy } from "./js/canopy.js";
import { ref } from "vue";

import variableWatcher from "../varGui/variableWatcher.vue";
import ToggleSection from "../varGui/ToggleSection.vue";
import customButton from "../varGui/customButton.vue";
import customDropdown from "../varGui/customDropdown.vue";

export default {
  name: "ShyCanopies",
  components: {
    variableWatcher,
    ToggleSection,
    customButton,
    customDropdown,
  },
  setup() {
    // Create reactive variables
    const twigLength = ref(4);
    const deviationAngle = ref(1.2);
    const branchSize = ref(300);
    const twigColor = ref("#0000FF");
    const bgColor = ref("#fff4d9");
    const circle = ref(true);
    const middleSeed = ref(true);
    const seeds = ref(10);
    const verticalMargin = ref(20);
    const horizontalMargin = ref(20);

    // Return the reactive variables
    return {
      twigLength,
      deviationAngle,
      branchSize,
      twigColor,
      bgColor,
      circle,
      middleSeed,
      seeds,
      verticalMargin,
      horizontalMargin,
    };
  },
  data() {
    return {
      scenarios: [
        {
          name: "Single Tree",
          twigLength: 3,
          deviationAngle: 1.8,
          branchSize: 300,
          twigColor: "#0000FF",
          bgColor: "#fff4d9",
          circle: true,
          middleSeed: true,
          seeds: 1,
          verticalMargin: 60,
          horizontalMargin: 60,
        },
        {
          name: "Single Sqaure",
          twigLength: 3,
          deviationAngle: 1.8,
          branchSize: 300,
          twigColor: "#0000FF",
          bgColor: "#fff4d9",
          circle: false,
          middleSeed: false,
          seeds: 1,
          verticalMargin: 60,
          horizontalMargin: 200,
        },
        {
          name: "Shy Canopies",
          twigLength: 2.5,
          deviationAngle: 1.4,
          branchSize: 300,
          twigColor: "#0000FF",
          bgColor: "#fff4d9",
          circle: false,
          middleSeed: false,
          seeds: 8,
          verticalMargin: 60,
          horizontalMargin: 60,
        },
        {
          name: "Imperfect Circle",
          twigLength: 3.2,
          deviationAngle: 1.3,
          branchSize: 90,
          twigColor: "#0000FF",
          bgColor: "#fff4d9",
          circle: true,
          middleSeed: false,
          seeds: 1,
          verticalMargin: 60,
          horizontalMargin: 60,
        },
        {
          name: "Small Blobs",
          twigLength: 3.0,
          deviationAngle: 1.8,
          branchSize: 34,
          twigColor: "#0000FF",
          bgColor: "#fff4d9",
          circle: false,
          middleSeed: false,
          seeds: 15,
          verticalMargin: 60,
          horizontalMargin: 60,
        },
        {
          name: "Pillar of Trees",
          twigLength: 3.0,
          deviationAngle: 1.8,
          branchSize: 60,
          twigColor: "#0000FF",
          bgColor: "#fff4d9",
          circle: false,
          middleSeed: false,
          seeds: 10,
          verticalMargin: 60,
          horizontalMargin: 320,
        },
      ],
      selectedScenario: null,
    };
  },
  watch: {
    twigLength(value) {
      this.canopy.plantDNA.r = value;
      this.$forceUpdate(); // Force a re-render
    },
    deviationAngle(value) {
      this.canopy.plantDNA.deviation = value;
    },
    branchSize(value) {
      this.canopy.plantDNA.stamina = value;
    },
    twigColor(value) {
      this.canopy.sceneSetting.branchColor = value;
    },
    bgColor(value) {
      this.canopy.sceneSetting.backgroundColor = value;
    },
    circle(value) {
      this.canopy.sceneSetting.circle = value;
    },
    middleSeed(value) {
      this.canopy.sceneSetting.middleSeed = value;
    },
    seeds(value) {
      this.canopy.sceneSetting.seeds = value;
    },
    verticalMargin(value) {
      this.canopy.sceneSetting.verticalMargin = value;
    },
    horizontalMargin(value) {
      this.canopy.sceneSetting.horizontalMargin = value;
    },
  },
  mounted() {
    const canvasElement = this.$refs.canvas;
    let width = canvasElement.clientWidth;
    let height = canvasElement.clientHeight;

    const draw = SVG().addTo(canvasElement).size(width, height);

    this.canopy = new Canopy(draw, width, height);

    const animate = () => {
      this.canopy.grow();
      // this.canopy.sceneSetting.seeds = this.sliderValue;
      requestAnimationFrame(animate);
    };

    animate();
  },
  methods: {
    regrow() {
      // Do something when header is clicked
      this.canopy.reset();
    },
    redraw() {
      // Do something when header is clicked
      this.canopy.drawBranches();
    },
    download() {
      // Do something when header is clicked
      this.canopy.download();
    },
    loadScenario() {
      if (this.selectedScenario) {
        const scenario = this.selectedScenario;
        for (const prop in scenario) {
          if (Object.prototype.hasOwnProperty.call(scenario, prop)) {
            this[prop] = scenario[prop];
          }
        }
        this.$nextTick(() => {
          this.regrow();
          this.$forceUpdate();
        });
      }
    },
  },
};
</script>

<style scoped>
h1 {
  font-size: 1.2rem;
  text-align: left;
}
</style>
