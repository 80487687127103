/* eslint-disable */
import Victor from "victor";
import * as dat from "dat.gui";

export class Canopy {
  constructor(canvas, width, height) {
    this.plantDNA = { r: 4, deviation: 1.2, stamina: 100 };
    this.sceneSetting = {
      circle: true,
      seeds: 10,
      middleSeed: true,
      verticalMargin: 20,
      horizontalMargin: 20,
      branchColor: "#3a362c",
      backgroundColor: "#fff4d9",
    };

    var self = this; // Store a reference to 'this'

    this.canvas = canvas;
    this.width = width;
    this.height = height;
    this.k = 10;
    this.reset();
  }

  reset() {
    this.canvas.clear();
    this.canvas
      .rect(this.width, this.height)
      .fill(this.sceneSetting.backgroundColor);
    this.r = this.plantDNA.r;
    this.w = this.r / Math.sqrt(2);
    this.grid = [];
    this.active = [];
    this.branchTips = [];
    this.branches = [];
    this.deviationAngle = this.plantDNA.deviation;
    this.diameter = Math.min(this.height, this.width) * 0.4;
    this.stamina = this.plantDNA.stamina;
    this.branchesArranged = false;

    this.centerPos = new Victor(this.width / 2, this.height / 2);

    this.cols = Math.floor(this.width / this.w);
    this.rows = Math.floor(this.height / this.w);

    for (var i = 0; i < this.cols * this.rows; i++) {
      this.grid[i] = undefined;
    }

    var numberOfSeeds = this.sceneSetting.seeds;

    for (var i = 0; i < numberOfSeeds; i++) {
      var maxAttempts = 1000; // Set a maximum number of attempts

      var randomPos;
      var attempts = 0;

      do {
        randomPos = this.getRandomPosition();
        attempts++;
      } while (this.checkWithinCanvas(randomPos) && attempts < maxAttempts);

      if (attempts === maxAttempts) {
        console.error(
          "Maximum attempts reached. Could not find valid position."
        );
      } else {
        if (this.sceneSetting.seeds == 1 && this.sceneSetting.middleSeed) {
          console.log("generating a middle seed");
          randomPos = new Victor(this.width / 2, this.height / 2);
        }
        var dna = this.getRandomDNA();
        this.newNode(
          randomPos,
          this.getRandomHexColor(),
          null,
          dna,
          this.stamina,
          null
        );
        this.canvas
          .circle(10)
          .fill(this.sceneSetting.branchColor)
          .center(randomPos.x, randomPos.y);
      }
    }
  }
  getRandomPosition() {
    var x = Math.floor(Math.random() * this.width);
    var y = Math.floor(Math.random() * this.height);
    var pos = new Victor(x, y);

    return pos;
  }

  newNode(position, color, angle, dna, stamina, father) {
    var index = this.cordToIndex(position);
    var cell = {
      position: position,
      color: color,
      angle: angle,
      dna: dna,
      stamina: stamina,
      father: father,
      partOfBranch: false,
      hasChild: false,
    };
    this.grid[index] = cell;
    this.active.push(cell);
  }

  grow() {
    for (var p = 0; p < 100; p++) {
      if (this.active.length > 0) {
        var index = Math.floor(Math.random() * this.active.length);
        var activeNode = this.active[index];
        var found = false;
        var everFound = false;
        for (var n = 0; n < this.k; n++) {
          var radius = this.r + Math.random() * this.r;
          radius = this.r;
          var radius = this.r + (Math.random() * this.r) / 2;
          var randomAngle = 0;
          if (activeNode.angle == null) {
            randomAngle = Math.random() * (2 * Math.PI);
          } else {
            randomAngle =
              -0.5 * this.deviationAngle + Math.random() * this.deviationAngle;
            randomAngle += activeNode.angle;
          }

          const x = Math.sin(randomAngle) * radius;
          const y = Math.cos(randomAngle) * radius;
          const samplePos = new Victor(x, y);
          samplePos.add(activeNode.position);

          var col = Math.floor(samplePos.x / this.w);
          var row = Math.floor(samplePos.y / this.w);

          if (row < 0 || row > this.rows || col < 0 || col > this.cols) {
            break;
          }

          if (this.checkWithinCanvas(samplePos)) {
            break;
          }

          var ok = true;

          for (var i = -2; i <= 2; i++) {
            for (var j = -2; j <= 2; j++) {
              var neighbour = this.grid[col + i + (row + j) * this.cols];
              if (neighbour != undefined) {
                var distance = samplePos.distance(neighbour.position);
                if (neighbour.dna == activeNode.dna) {
                  if (distance < this.r) {
                    ok = false;
                  }
                } else {
                  if (distance < 3 * this.r) {
                    ok = false;
                  }
                }
              }
            }
          }

          if (activeNode.stamina < 1) {
            ok = false;
          }

          if (ok) {
            found = true;
            activeNode.hasChild = true;
            this.newNode(
              samplePos,
              activeNode.color,
              randomAngle,
              activeNode.dna,
              activeNode.stamina - 1,
              this.cordToIndex(activeNode.position)
            );

            this.canvas
              .line(
                activeNode.position.x,
                activeNode.position.y,
                samplePos.x,
                samplePos.y
              )
              .stroke({
                color: this.sceneSetting.branchColor,
                width: 1,
              });
          }
        }

        if (!found) {
          this.active.splice(index, 1);
          if (!activeNode.hasChild) {
            this.branchTips.push(this.cordToIndex(activeNode.position));
          }
        }
      } else {
        if (!this.branchesArranged) {
          this.arrangeBranches();
          this.drawBranches();
          this.branchesArranged = true;
        }
      }
    }
  }

  checkWithinCanvas(position) {
    var outsideCanvas = false;
    if (
      position.x > this.width - this.sceneSetting.horizontalMargin ||
      position.x < this.sceneSetting.horizontalMargin ||
      position.y > this.height - this.sceneSetting.verticalMargin ||
      position.y < this.sceneSetting.verticalMargin
    ) {
      outsideCanvas = true;
    }
    if (this.sceneSetting.circle) {
      if (position.distance(this.centerPos) > this.diameter) {
        outsideCanvas = true;
      }
    }
    return outsideCanvas;
  }

  arrangeBranches() {
    this.canvas.clear();
    this.canvas
      .rect(this.width, this.height)
      .fill(this.sceneSetting.backgroundColor);

    while (this.branchTips.length > 0) {
      var longestBranch = -1;
      var longestBranchTipIndex = null;
      var branchTipIndex = null;

      // find the longest branch
      for (var i = 0; i < this.branchTips.length; i++) {
        var length = 0;

        var nextNode = this.grid[this.grid[this.branchTips[i]].father];
        while (nextNode != null) {
          if (nextNode.partOfBranch) {
            break;
          }
          nextNode = this.grid[nextNode.father];
          length++;
        }

        if (length > longestBranch) {
          longestBranch = length;
          longestBranchTipIndex = this.branchTips[i];
          branchTipIndex = i;
        }
      }

      this.branchTips.splice(branchTipIndex, 1);

      var branch = {
        polyline: [],
        color: "#000",
      };

      // remove the tip and the fathers
      var nextBranchNode = this.grid[longestBranchTipIndex];
      while (nextBranchNode != null) {
        if (nextBranchNode.partOfBranch) {
          branch.polyline.push([
            nextBranchNode.position.x,
            nextBranchNode.position.y,
          ]);
          break;
        }
        branch.polyline.push([
          nextBranchNode.position.x,
          nextBranchNode.position.y,
        ]);
        nextBranchNode.partOfBranch = true;
        nextBranchNode = this.grid[nextBranchNode.father];
      }

      this.branches.push(branch);
    }
  }

  drawBranches() {
    this.canvas.clear();
    this.canvas
      .rect(this.width, this.height)
      .fill(this.sceneSetting.backgroundColor);
    this.branches.forEach((branch) => {
      var reversedPolyline = this.reverseArrayOfArrays(branch.polyline.slice());
      this.canvas
        .polyline(reversedPolyline)
        .stroke({
          color: this.sceneSetting.branchColor,
          width: 1,
        })
        .fill("none");
    });
  }

  reverseArrayOfArrays(arr) {
    return arr.slice().reverse();
  }

  getRandomHexColor() {
    const randomColor = Math.floor(Math.random() * 16777215).toString(16); // Generate a random hexadecimal value
    return `#${randomColor.padStart(6, "0")}`; // Ensure it has 6 digits
  }

  cordToIndex(position) {
    var i = Math.floor(position.x / this.w);
    var j = Math.floor(position.y / this.w);
    var index = i + j * this.cols;
    return index;
  }

  draw() {
    this.grid.forEach((cell) => {
      if (cell !== undefined) {
        this.canvas
          .circle(4)
          .center(cell.position.x, cell.position.y)
          .fill(cell.color);
      }
    });
  }

  getRandomDNA() {
    const dna = Math.floor(Math.random() * 1000);
    return dna;
  }

  download() {
    var text = this.canvas.svg();
    var fileType = "image/svg+xml";
    var fileName = "myCSV.svg";

    var blob = new Blob([text], { type: fileType });

    var a = document.createElement("a");
    a.download = fileName;
    a.href = URL.createObjectURL(blob);
    a.dataset.downloadurl = [fileType, a.download, a.href].join(":");
    a.style.display = "none";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    setTimeout(function () {
      URL.revokeObjectURL(a.href);
    }, 1500);
  }
}
