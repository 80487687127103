import { createRouter, createWebHistory } from "vue-router";
import HomeView from "../views/HomeView.vue";
import CityScapes from "../views/projects/CityScapes/CityScapes.vue";
import ShyCanopies from "../views/projects/ShyCanopies/ShyCanopies.vue";
import Meadow from "../views/projects/Meadow/Meadow.vue";
import ThreeBodies from "../views/projects/ThreeBodies/ThreeBodies.vue";
import PendulumsSwing from "../views/projects/Pendulums/PendulumsSwing.vue";
import FlockingBirds from "../views/projects/FlockingBirds/FlockingBirds.vue";
import ClifforAttractors from "../views/projects/ClifforAttractors/ClifforAttractors.vue";
import FlowFields from "../views/projects/FlowFields/FlowFields.vue";
import BulgeLines from "../views/projects/BulgeLines/BulgeLines.vue";
import Droplet from "../views/projects/Droplet/Droplet.vue";
import Plant from "../views/projects/Plant/Plant.vue";
import Diamonds from "../views/projects/Diamonds/Diamonds.vue";
import Bach from "../views/projects/Bach/Bach.vue";

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  { path: "/CityScapes", name: "CityScapes", component: CityScapes },
  { path: "/ShyCanopies", name: "ShyCanopies", component: ShyCanopies },
  { path: "/Meadow", name: "Meadow", component: Meadow },
  { path: "/ThreeBodies", name: "ThreeBodies", component: ThreeBodies },
  { path: "/Pendulums", name: "Pendulums", component: PendulumsSwing },
  { path: "/FlockingBirds", name: "FlockingBirds", component: FlockingBirds },
  { path: "/FlowFields", name: "FlowFields", component: FlowFields },
  { path: "/BulgeLines", name: "BulgeLines", component: BulgeLines },
  { path: "/Droplet", name: "Droplet", component: Droplet },
  { path: "/Plant", name: "Plant", component: Plant },
  { path: "/Diamonds", name: "Diamonds", component: Diamonds },
  { path: "/Bach", name: "Bach", component: Bach },

  {
    path: "/ClifforAttractors",
    name: "ClifforAttractors",
    component: ClifforAttractors,
  },

  {
    path: "/about",
    name: "about",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/AboutView.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
