import { DataPoint } from "./dataPoint";

export class DataArray {
  constructor(simplex, settings) {
    this.simplex = simplex;
    this.settings = settings;
    this.xLen = this.settings.arraySize.x;
    this.yLen = this.settings.arraySize.y;
    this.stepsX = 1;
    this.stepsY = 1;
    this.stepsTime = 1;

    // eslint-disable-next-line
    this.data = [...Array(this.xLen)].map((e) => Array(this.yLen));
  }

  updateDimensions() {
    this.xLen = this.settings.arraySize.x;
    this.yLen = this.settings.arraySize.y;

    this.createDataArray();
  }

  updateAnimationSpeed() {
    this.animationSpeed = 1;
  }

  updateNoiseSize() {
    this.noiseSize = this.settings.noise.size / 100;
  }

  findHidden() {}

  updateVariables() {
    this.updateDimensions();
    this.updateAnimationSpeed();
    this.updateNoiseSize();

    this.lastX = this.xLen - 1;
    this.lastY = this.yLen - 1;

    for (var x = 0; x < this.xLen; x++) {
      for (var y = 0; y < this.yLen; y++) {
        this.data[x][y].updateVariables();
      }
    }
  }

  createDataArray() {
    this.data = [];
    for (var x = 0; x < this.xLen; x++) {
      this.data.push([]);
      for (var y = 0; y < this.yLen; y++) {
        this.data[x].push(new DataPoint(this.settings, x, y));
      }
    }
  }

  clearCollisions() {
    for (var x = 0; x < this.xLen; x++) {
      for (var y = 0; y < this.yLen; y++) {
        this.data[x][y].clearCollisions();
      }
    }
  }

  resetVisibility() {
    for (var x = 0; x < this.xLen; x++) {
      for (var y = 0; y < this.yLen; y++) {
        this.data[x][y].setInSight();
      }
    }
  }

  updateDataArray() {
    this.stepsX +=
      (this.settings.noise.speed / 100) * this.settings.noise.directionX;
    this.stepsY +=
      (this.settings.noise.speed / 100) * this.settings.noise.directionY;
    this.stepsTime +=
      (this.settings.noise.speed / 100) * this.settings.noise.directionTime;

    for (var x = 0; x < this.xLen; x++) {
      for (var y = 0; y < this.yLen; y++) {
        var value = this.simplex.noise3D(
          (x * this.settings.noise.size) / 100 + this.stepsX,
          (y * this.settings.noise.size) / 100 + this.stepsY,
          this.stepsTime
        );

        this.data[x][y].updateValue(value);
        this.data[x][y].setInSight();
      }
    }
  }
}
