<template>
  <div class="landing-page">
    <h1 class="name">STEFAN VAN DER HEIJDEN</h1>
    <div class="subtitle">Generative art project portfolio</div>
  </div>
</template>

<script>
export default {
  name: "HelloWorld",
};
</script>

<style scoped>
.landing-page {
  max-width: 1000px;
  margin: 0 auto;
  text-align: left;
  margin-bottom: 50px;
  /* padding: 20px; */
}
.name {
  color: var(--line-color); /* Using CSS variable */
  font-family: "Nunito Sans", sans-serif;
  font-size: 1.5rem;
  font-weight: 600; /* Semi-bold */
  text-align: center;
  margin-bottom: -6px;
}

.subtitle {
  color: var(--line-color); /* Using CSS variable */
  font-family: "Nunito Sans", sans-serif;
  font-size: 0.8rem;
  text-align: center;
  width: 100%;
}

h1 {
  font-size: 2rem;
  margin-bottom: 10px;
}

p {
  margin-bottom: 20px;
  line-height: 1.6;
}
</style>
