import { Cell } from "./Cell";

export class City {
  constructor(width, height) {
    this.width = width;
    this.height = height;
    this.settings = {
      waveSize: 3,
      speed: 1,
      border: 100,
      resolution: 20,
      lineColor: "#000000",
      roofColor: "#F3EADF",
      shadeColor: "#96A6BE",
      brightSide: "#ACB8D9",
      grassColor: "#008E8C",
      waterColor: "#394358",
      backgroundColor: "#ffcccc",
      animate: true,
      lineWidth: 1,
      heightMultiplier: 4,
      flatValue: 50,
      grassValue: 45,
      waterValue: 40,
      angle: 45,
    };
    this.gridCount = 1;
    this.gridCellSize = 1;
    this.valueArray = [];
    this.p5 = null;
    this.timeStamp = 0;
  }

  setup(p5) {
    this.p5 = p5;
  }

  reset() {
    console.log("reset from the city");
    this.valueArray = [];
    this.gridCount = Math.floor(
      (this.width - 2 * this.settings.border) / this.settings.resolution
    );
    this.gridCellSize =
      (this.width - 2 * this.settings.border) / this.gridCount;

    for (let x = 0; x < this.gridCount; x++) {
      this.valueArray.push([]);
      for (let y = 0; y < this.gridCount; y++) {
        this.valueArray[x].push(
          new Cell(this.p5, x, y, 0, this.gridCellSize, this.settings)
        );
      }
    }
  }

  draw() {
    if (this.settings.animate) {
      this.timeStamp += this.settings.speed * 0.003;
    }
    this.p5.background(this.p5.color("#ffffff"));
    this.p5.fill(this.p5.color(this.settings.backgroundColor));
    this.p5.rect(60, 60, this.width - 120, this.width - 120);

    this.p5.noStroke();
    this.p5.fill(this.p5.color("#828282"));
    this.p5.text(
      "Cityscapes by Stefan van der Heijden and you",
      60,
      this.width - 47
    );
    for (let x = 0; x < this.gridCount; x++) {
      for (let y = 0; y < this.gridCount; y++) {
        var d = this.p5.noise(
          x * (this.settings.waveSize / 20),
          y * (this.settings.waveSize / 20),
          this.timeStamp
        );

        this.valueArray[x][y].getValue(d);
        this.valueArray[x][y].drawSelf();
      }
    }
  }

  downloadHD() {
    // Temporarily resize the canvas to 4 times its original resolution
    this.p5.resizeCanvas(this.width * 4, this.height * 4);
    this.p5.scale(4); // Scale up the drawing by 4
    this.draw(); // Redraw the city at the higher resolution

    // Save the canvas as an image
    this.p5.saveCanvas("cityscapeHD", "png");

    // Immediately resize the canvas back to its original resolution
    // and scale back the drawing
    this.p5.scale(0.25); // Scale back down
    this.p5.resizeCanvas(this.width, this.height);
    this.draw(); // Redraw the city at its original resolution to restore the view
  }
}
