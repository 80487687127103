export const projectsArray = [
  {
    id: "000",
    title: "Flow Fields",
    description:
      "Visualization of Flow Field using P5JS. Simplex Noise is used to generate a pseudo-random flow field.",
    link: "/FlowFields",
    tags: ["P5JS", "Math"],
  },
  {
    id: "013",
    title: "City Scapes",
    description:
      "Very customizable isualization of city landscape, land and water. P5JS in combination with Perlin Noise.",
    link: "/CityScapes",
    tags: ["P5JS"],
  },
  {
    id: "001",
    title: "Shy Canopies",
    description:
      "Shy Canopy Phenomenon explored using SVG rendering. Output can be used to plot on a Penplotter.",
    link: "/ShyCanopies",
    tags: ["SVG", "Penplotter", "Nature"],
  },
  {
    id: "002",
    title: "Three Body Simulator",
    description:
      "From the book 'Three Body Problem', a simulator that uses Newtonian theory of gravitation. Can be used to explore the effect of multiple suns in our Solar System.",
    link: "ThreeBodies",
    tags: ["P5JS", "Math", "Nature"],
  },
  {
    id: "006",
    title: "Meadow",
    description:
      "A visualizer of curved surfaces to create complex penplotter visuals. Simplex noise plotted for SVG output.",
    link: "/Meadow",
    tags: ["SVG", "Penplotter", "Math"],
  },
  {
    id: "008",
    title: "Clifford Attractors",
    description:
      "Clifford Attractors are a set of formulas that utilize 4 basic variables to generate a wide variety of visual representations. Unpredicatable outcomes can be saved as high-res images.",
    link: "ClifforAttractors",
    tags: ["P5JS", "Math"],
  },
  {
    id: "003",
    title: "Pendulums",
    description:
      "A simulator for a number of pendulums, used to generate vector files for my penplotter. Not so easy to get good output, but fun anyway.",
    link: "Pendulums",
    tags: ["SVG", "Math", "Penplotter"],
  },
  {
    id: "004",
    title: "Flocking Behaviour",
    description:
      "A flocking behaviour simulator. Reynolds (1987) proposed a set of simple rules which, when applied to each individual, help explain flocking behaviour in birds.",
    link: "FlockingBirds",
    tags: ["P5JS", "Nature"],
  },
  {
    id: "005",
    title: "Bulge Lines",
    description:
      "A basic visualizer of a bulging mesh. Outputs SVG files which can be used for penplots.",
    link: "/BulgeLines",
    tags: ["SVG", "Penplotter"],
  },

  {
    id: "007",
    title: "Droplet",
    description:
      "A simple collection of splines that together create a moving droplet. Uses perlin noise and outputes SVG files.",
    link: "/Droplet",
    tags: ["SVG", "Penplotter", "Math"],
  },

  {
    id: "009",
    title: "Bach Pipes",
    description:
      "Audio visualizer tailor-made for organ music. Especially nice when projected on a ceiling.",
    link: "/Bach",
    tags: ["P5JS", "Music"],
  },
  {
    id: "010",
    title: "Plant Simulator",
    description:
      "Simulation of plant growth using pseudo-randomness and a set of variables (DNA).",
    link: "/Plant",
    tags: ["P5JS", "Nature"],
  },
  {
    id: "011",
    title: "Diamonds",
    description: "Happy caleidoscopic visualization using P5JS.",
    link: "/Diamonds",
    tags: ["P5JS"],
  },
  // {
  //   id: "012",
  //   title: "Gravity Shoot",
  //   description: "Basic gravity simulator with planets and astroids.",
  //   link: "link1",
  //   tags: ["P5JS"],
  // },
];
