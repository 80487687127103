// Meadow.js
import { DataArray } from "./dataArray";
import SimplexNoise from "simplex-noise";
import { VisualArray } from "./visualArray";

export class Meadow {
  constructor(canvas, width, height, document) {
    this.draw = canvas;
    this.width = width;
    this.height = height;
    this.document = document;
    this.margin = 100;

    this.settings = {};
    this.settings.canvas = { width: this.width, height: this.height };
    this.settings.arraySize = { x: 16, y: 16, width: 80, height: 80 };

    this.settings.noise = {
      size: 5,
      amplifier: 150,
      speed: 1,
      directionX: 0.5,
      directionY: 0,
      directionTime: 0,
    };
    this.settings.base = {
      height: 100,
      lineCount: 10,
      enable: true,
      contours: true,
      verticalEdges: true,
      edgeLine: true,
      straigthBase: true,
    };
    this.settings.camera = { angle: 45 };
    this.settings.gridVisuals = {
      lineColor: "#000000",
      backgroundColor: "#FFF",
      width: 1,
      margin: 100,
    };

    this.simplex = new SimplexNoise();
    this.dataArray = new DataArray(this.simplex, this.settings);

    this.visualArrayX = new VisualArray(this.draw, this.settings);
    this.visualArrayY = new VisualArray(this.draw, this.settings);
  }

  updateAndDraw() {
    this.draw.clear();
    this.draw
      .rect(this.width, this.height)
      .fill(this.settings.gridVisuals.backgroundColor);

    this.dataArray.updateVariables();

    this.visualArrayX.setDepth(this.settings.arraySize.y);
    this.visualArrayY.setDepth(this.settings.arraySize.x);
    this.dataArray.updateDataArray();
    this.dataArray.findHidden();
    this.fillLines(this.dataArray.data);

    this.visualArrayX.checkOutOfSight(this.visualArrayX);
    this.visualArrayX.checkOutOfSight(this.visualArrayY);

    this.visualArrayY.checkOutOfSight(this.visualArrayX);
    this.visualArrayY.checkOutOfSight(this.visualArrayY);

    this.visualArrayX.checkCollisionWithArray(this.visualArrayX, "self");
    this.visualArrayX.checkCollisionWithArray(this.visualArrayY, "other");

    this.visualArrayX.drawSelf(false);

    this.dataArray.clearCollisions();

    this.visualArrayY.checkCollisionWithArray(this.visualArrayX, "other");
    this.visualArrayY.checkCollisionWithArray(this.visualArrayY, "self");

    this.visualArrayY.drawSelf(this.settings.base.edgeLine);

    this.dataArray.resetVisibility();
    this.visualArrayY.clear();
    this.visualArrayX.clear();
  }

  fillLines(dataArrayTemp) {
    for (let x = 0; x < this.dataArray.xLen; x++) {
      for (let y = 0; y < this.dataArray.yLen; y++) {
        this.visualArrayY.addPoint(dataArrayTemp[x][y], x);
      }
    }

    for (let y = 0; y < this.dataArray.yLen; y++) {
      for (let x = 0; x < this.dataArray.xLen; x++) {
        this.visualArrayX.addPoint(dataArrayTemp[x][y], y);
      }
    }
  }

  download() {
    var text = this.draw.svg();
    var fileType = "image/svg+xml";
    var fileName = "myCSV.svg";

    var blob = new Blob([text], { type: fileType });

    var a = document.createElement("a");
    a.download = fileName;
    a.href = URL.createObjectURL(blob);
    a.dataset.downloadurl = [fileType, a.download, a.href].join(":");
    a.style.display = "none";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    setTimeout(function () {
      URL.revokeObjectURL(a.href);
    }, 1500);
  }
}
