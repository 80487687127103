export class Cell {
  constructor(p5, x, y, value, gridSize, settings) {
    this.p5 = p5;
    this.x = x;
    this.y = y;
    this.value = value;
    this.gridSize = gridSize;
    this.borderWidth = settings.border;

    this.heightMultiplier = settings.heightMultiplier;
    this.flatValue = settings.flatValue / 100;
    this.grassValue = settings.grassValue / 100;
    this.waterValue = settings.waterValue / 100;
    this.lineWidth = settings.lineWidth;
    this.settings = settings;

    this.lineColor = this.p5.color(settings.lineColor);
    this.roofColor = this.p5.color(settings.roofColor);
    this.shadeColor = this.p5.color(settings.shadeColor);
    this.brightSide = this.p5.color(settings.brightSide);
    this.grassColor = this.p5.color(settings.grassColor);
    this.waterColor = this.p5.color(settings.waterColor);
  }

  getValue(value) {
    this.value = value;
  }

  drawSelf() {
    if (this.value > this.flatValue) {
      this.drawFlat(true);
    } else if (this.value > this.grassValue && this.value < this.flatValue) {
      this.drawFlat(false);
    } else if (this.value > this.waterValue && this.value < this.grassValue) {
      this.drawGrass();
    } else {
      this.drawWater();
    }
  }

  drawWater() {
    // let darknessMult = this.value / this.waterValue;

    this.updateCoordinates(false);

    this.p5.push();
    this.p5.fill(this.waterColor);
    this.p5.noStroke();

    //draw a simple square
    this.p5.beginShape();

    this.p5.vertex(this.gx, this.gy);
    this.p5.vertex(this.dx, this.dy);
    this.p5.vertex(this.ex, this.ey);
    this.p5.vertex(this.fx, this.fy);
    this.p5.vertex(this.gx, this.gy);

    this.p5.endShape(this.p5.CLOSE);

    this.p5.noFill();

    this.p5.stroke(this.p5.color(this.settings.lineColor));
    this.p5.strokeWeight(this.lineWidth);

    this.p5.beginShape();

    //draw the black lines around the square
    this.p5.vertex(this.bx, this.by);
    this.p5.vertex(this.cx, this.cy);
    this.p5.vertex(this.dx, this.dy);
    this.p5.vertex(this.ex, this.ey);
    this.p5.vertex(this.fx, this.fy);
    this.p5.vertex(this.ax, this.ay);
    this.p5.vertex(this.bx, this.by);

    this.p5.endShape();
    this.p5.pop();
  }

  drawGrass() {
    this.updateCoordinates(false);

    this.p5.push();
    this.p5.fill(this.grassColor);
    this.p5.noStroke();

    //draw a simple square
    this.p5.beginShape();

    this.p5.vertex(this.gx, this.gy);
    this.p5.vertex(this.dx, this.dy);
    this.p5.vertex(this.ex, this.ey);
    this.p5.vertex(this.fx, this.fy);
    this.p5.vertex(this.gx, this.gy);

    this.p5.endShape(this.p5.CLOSE);

    this.p5.noFill();
    this.p5.stroke(this.p5.color(this.settings.lineColor));
    this.p5.strokeWeight(this.lineWidth);

    this.p5.beginShape();

    //draw the black lines around the square
    this.p5.vertex(this.bx, this.by);
    this.p5.vertex(this.cx, this.cy);
    this.p5.vertex(this.dx, this.dy);
    this.p5.vertex(this.ex, this.ey);
    this.p5.vertex(this.fx, this.fy);
    this.p5.vertex(this.ax, this.ay);
    this.p5.vertex(this.bx, this.by);

    this.p5.endShape();
    this.p5.pop();
  }

  updateCoordinates(rise) {
    let hx, hy;
    if (rise) {
      // Calculate the height based on the value and the height multiplier
      let height = this.heightMultiplier * 100 * (this.value - this.flatValue);
      // Convert the angle from degrees to radians for trigonometric functions
      let angleRadians = this.p5.radians(this.settings.angle);
      // Calculate the offsets based on the angle
      hx = height * this.p5.cos(angleRadians);
      hy = height * this.p5.sin(angleRadians);
    } else {
      hx = 0;
      hy = 0;
    }

    let g = this.gridSize;
    let x = this.borderWidth + this.x * g;
    let y = this.borderWidth + this.y * g;

    this.ax = x + g;
    this.ay = y;

    this.bx = x + g;
    this.by = y + g;

    this.cx = x;
    this.cy = y + g;

    this.dx = this.cx - hx;
    this.dy = this.cy - hy;

    this.ex = x - hx;
    this.ey = y - hy;

    this.fx = this.ax - hx;
    this.fy = this.ay - hy;

    this.gx = this.bx - hx;
    this.gy = this.by - hy;
  }

  drawFlat(rise) {
    this.updateCoordinates(rise);

    if (rise) {
      this.p5.push();
      this.p5.fill(this.shadeColor);
      this.p5.noStroke();

      this.p5.beginShape();

      this.p5.vertex(this.bx, this.by);
      this.p5.vertex(this.gx, this.gy);
      this.p5.vertex(this.fx, this.fy);
      this.p5.vertex(this.ax, this.ay);
      this.p5.vertex(this.bx, this.by);

      this.p5.endShape(this.p5.CLOSE);

      this.p5.fill(this.brightSide);
      this.p5.beginShape();

      this.p5.vertex(this.bx, this.by);
      this.p5.vertex(this.gx, this.gy);
      this.p5.vertex(this.dx, this.dy);
      this.p5.vertex(this.cx, this.cy);
      this.p5.vertex(this.bx, this.by);

      this.p5.endShape(this.p5.CLOSE);
      this.p5.pop();
    }

    this.p5.push();
    this.p5.fill(this.roofColor);
    this.p5.noStroke();

    //draw a simple square
    this.p5.beginShape();

    this.p5.vertex(this.gx, this.gy);
    this.p5.vertex(this.dx, this.dy);
    this.p5.vertex(this.ex, this.ey);
    this.p5.vertex(this.fx, this.fy);
    this.p5.vertex(this.gx, this.gy);

    this.p5.endShape(this.p5.CLOSE);

    this.p5.noFill();
    this.p5.stroke(this.p5.color(this.settings.lineColor));
    this.p5.strokeWeight(this.lineWidth);

    this.p5.beginShape();

    //draw the black lines around the square
    this.p5.vertex(this.bx, this.by);
    this.p5.vertex(this.cx, this.cy);
    this.p5.vertex(this.dx, this.dy);
    this.p5.vertex(this.ex, this.ey);
    this.p5.vertex(this.fx, this.fy);
    this.p5.vertex(this.ax, this.ay);
    this.p5.vertex(this.bx, this.by);

    this.p5.endShape();
    this.p5.pop();
  }
}
