<template>
  <router-link :to="link" :class="{ 'project-tile': true, inactive: inactive }">
    <div class="description">
      <h3>{{ title }}</h3>
      <p>{{ description }}</p>
      <!-- <div class="tag-bar">
        <div v-if="tags && tags.length > 0">
          <span v-for="tag in tags" :key="tag" :class="getClassForTag(tag)">{{
            tag
          }}</span>
        </div>
      </div> -->
    </div>
    <img :src="getImagePath()" alt="Project Image" class="project-image" />
  </router-link>
</template>

<script>
export default {
  props: {
    title: String,
    description: String,
    link: String,
    tags: Array,
    index: Number,
    inactive: Boolean,
    selectedTags: Array,
  },
  methods: {
    getClassForTag(tag) {
      return {
        tag: this.selectedTags.includes(tag),
        inactiveTag: !this.selectedTags.includes(tag),
      };
    },
    getImagePath() {
      try {
        return require(`./images/${this.title}.png`);
      } catch (error) {
        this.imageExists = false;
        return "./images/Shy Canopies.png"; // Return a fallback image path or an empty string
      }
    },
  },
};
</script>

<style scoped>
.project-tile {
  text-decoration: none;
  color: inherit;
  position: relative;
  /* border: 1px solid var(--line-color); */
  /* padding: 5px; */
  margin: 5px;
  transition: transform 0.3s ease, opacity 0.3s ease;
  vertical-align: middle;
}
.description {
  opacity: 0%;
  background-color: rgba(255, 255, 255, 0.85);
  margin-left: auto;
  margin-right: auto;
  z-index: 1000;
  position: absolute;
  height: 100%;
  padding: 20px;
}

.project-tile:hover .description {
  opacity: 100%;
}

/* Add a little scale effect on hover if desired */
.project-tile:hover {
  transform: scale(1.05);
}

.tag-bar {
  /* position: absolute; */
  /* bottom: 0px; */
  /* width: 100%; */
  display: flex;
  justify-content: center;
  /* padding: 5px; */
}

.tag {
  font-size: 0.7rem;
  padding: 2px 6px;
  /* bottom: 0px; */
  color: var(--button-text);
  border: 1px solid var(--line-color);
  margin: 0px 5px;
}

.inactiveTag {
  font-size: 0.7rem;
  padding: 2px 6px;
  /* bottom: 0px; */
  background-color: var(--button-background);
  color: var(--button-text);
  margin: 0px 5px;
}

.inactive {
  opacity: 0.2; /* Adjust the opacity to your preference */
  pointer-events: none; /* Disable pointer events so it's non-clickable */
}

.project-image {
  visibility: visible;
  height: 100%; /* Set the width to fill the container */
  width: 100%;
  position: absolute;
  left: 0px;
  top: 0px;
  z-index: 100;
  object-fit: cover;
}

.project-tile:hover .project-image {
  /* visibility: hidden; */
  /* opacity: 30%; */
}
</style>
