<template>
  <div id="container">
    <div id="inputs">
      <div id="labelText">{{ label }}</div>

      <template v-if="inputType === 'slider'">
        <input v-model.number="internalValue" id="textBox" class="css-input" />
      </template>
      <template v-else-if="inputType === 'color-picker'">
        <input type="color" v-model="internalValue" id="colorPicker" />
      </template>
      <template v-else-if="inputType === 'boolean'">
        <ToggleButton
          v-model="internalValue"
          id="toggleButton"
          onLabel="On"
          offLabel="Off"
          class="customToggle"
        />
      </template>
    </div>
    <template v-if="inputType === 'slider'">
      <input
        type="range"
        class="istyle"
        :min="min"
        :max="max"
        :step="stepSize"
        v-model.number="internalValue"
      />
    </template>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import ToggleButton from "primevue/togglebutton";

export default defineComponent({
  props: {
    label: String,
    value: { type: [String, Number, Boolean] },
    min: Number,
    max: Number,
    stepSize: { type: Number, default: 1 },
    inputType: { type: String, default: "slider" },
  },
  data() {
    return {
      internalValue: this.value,
    };
  },
  watch: {
    internalValue(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.$emit("update:value", newVal);
      }
    },
    value(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.internalValue = newVal;
      }
    },
  },
  components: {
    ToggleButton,
  },
});
</script>

<style scoped>
#container {
  margin: 5px;
  padding: 5px;
}
#inputs {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

#labelText {
  text-align: left;
  font-size: 0.9rem;
}

#textBox {
  width: 40px;
}

#slider {
  margin: 0px 15px 0px 5px;
  width: 75%;
  top: 50%;
}

#colorPicker {
  padding: 0;
}

input[type="range"] {
  -webkit-appearance: none;
  margin: 10px 0;
  width: 100%;
}
input[type="range"]:focus {
  outline: none;
}
input[type="range"]::-webkit-slider-runnable-track {
  width: 100%;
  height: 6px;
  cursor: pointer;
  animate: 0.2s;
  box-shadow: 0px 0px 0px #000000;
  background: #f0eeee;
  border-radius: 1px;
  border: 1px solid #000000;
}
input[type="range"]::-webkit-slider-thumb {
  box-shadow: 0px 0px 1px #000000;
  border: 1px solid #000000;
  height: 16px;
  width: 10px;
  border-radius: 1px;
  background: #ffffff;
  cursor: pointer;
  -webkit-appearance: none;
  margin-top: -5px;
}
input[type="range"]:focus::-webkit-slider-runnable-track {
  background: #f0eeee;
}
input[type="range"]::-moz-range-track {
  width: 100%;
  height: 6px;
  cursor: pointer;
  animate: 0.2s;
  background: #f0eeee;
  border-radius: 1px;
  border: 1px solid var(--line-color);
}
input[type="range"]::-moz-range-thumb {
  box-shadow: 0px 0px 1px #000000;
  border: 1px solid var(--line-color);
  height: 23px;
  width: 10px;
  border-radius: 1px;
  background: #ffffff;
  cursor: pointer;
}
input[type="range"]::-ms-track {
  width: 100%;
  height: 9px;
  cursor: pointer;
  background: transparent;
  border-color: transparent;
  color: transparent;
}
input[type="range"]::-ms-fill-lower {
  background: #f0eeee;
  border: 1px solid var(--line-color);
  border-radius: 2px;
}
input[type="range"]::-ms-fill-upper {
  background: #f0eeee;
  border: 1px solid var(--line-color);
  border-radius: 2px;
}
input[type="range"]::-ms-thumb {
  box-shadow: 0px 0px 1px #000000;
  border: 1px solid var(--line-color);
  height: 23px;
  width: 10px;
  border-radius: var(--border-radius-small);
  background: #ffffff;
  cursor: pointer;
}
input[type="range"]:focus::-ms-fill-lower {
  background: #f0eeee;
}
input[type="range"]:focus::-ms-fill-upper {
  background: #f0eeee;
}

.css-input {
  font-size: 0.8rem;
  padding: 0px 5px;
  border-width: 1px;
  border-color: var(--line-color);
  background-color: #ffffff;
  color: var(--text-color);
  background-color: var(--background-color);
  border-style: solid;
  border-radius: var(--border-radius-small);
  text-align: center;
}
.css-input:focus {
  outline: none;
}

.customToggle {
  height: 10px;
  border-radius: var(--border-radius-small);
  font-size: 0.8rem;
  border: solid 1px var(--line-color);
  background-color: var(--background-color);
  color: var(--text-color);
  width: 40px;
  text-align: center;
}

.customToggle:hover {
  transform: scale(1.1);
}
</style>
