<template>
  <nav class="navigation-bar">
    <!-- <div class="brand">SvdH</div> -->
    <div class="menu">
      <router-link to="/">Home</router-link>
      <router-link to="/about">Contact</router-link>
    </div>
    <ToggleButton
      class="custom-toggle"
      v-model="darkMode"
      onIcon="pi pi-sun"
      offIcon="pi pi-moon"
      onLabel=""
      offLabel=""
    />
  </nav>
</template>

<script>
import ToggleButton from "primevue/togglebutton";

export default {
  components: {
    ToggleButton,
  },
  data() {
    return {
      darkMode: false,
    };
  },
  watch: {
    darkMode(value) {
      document.body.classList.toggle("dark-mode", value);
    },
  },
};
</script>

<style scoped>
.navigation-bar {
  background-color: var(--background-color);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000; /* Set the z-index higher than other elements */
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 10px;
}

.brand {
  font-size: 1.5rem;
}

.menu a {
  text-decoration: none;
  margin: 0 10px;
  color: var(--text-color);
  padding: 0px 5px;
  font-size: 0.8rem;
}

.menu a:hover {
  background-color: var(--button-hover-background);
}

.dark-mode-toggle {
  display: flex;
  align-items: center;
}

.p-checkbox {
  margin: 0;
}
</style>
