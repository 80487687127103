<template>
  <div class="canvas" id="canvas" ref="canvas"></div>
  <div id="custom_variables">
    <ToggleSection title="Controls" :visibleAtStart="true">
      <customButton
        headerText="Download Vector File"
        @header-clicked="download"
      />
    </ToggleSection>

    <ToggleSection
      title="Variables"
      class="custom_variables"
      :master="true"
      :visibleAtStart="true"
    >
      <ToggleSection title="Grid">
        <variableWatcher
          label="X Count"
          v-model:value="arraySizeX"
          :min="2"
          :max="60"
          :stepSize="1"
        />
        <variableWatcher
          label="Y Count"
          v-model:value="arraySizeY"
          :min="2"
          :max="60"
          :stepSize="1"
        />
        <variableWatcher
          label="X Length"
          v-model:value="width"
          :min="10"
          :max="100"
          :stepSize="1"
        />
        <variableWatcher
          label="Y Length"
          v-model:value="height"
          :min="10"
          :max="100"
          :stepSize="1"
        />
      </ToggleSection>
      <ToggleSection title="Noise">
        <variableWatcher
          label="Noise Size"
          v-model:value="noiseSize"
          :min="1"
          :max="20"
          :stepSize="0.1"
        />
        <variableWatcher
          label="Noise Amplification"
          v-model:value="amplifier"
          :min="1"
          :max="300"
          :stepSize="1"
        />
        <variableWatcher
          label="Noise Speed (sum)"
          v-model:value="speed"
          :min="0.01"
          :max="10"
          :stepSize="0.01"
        />
        <variableWatcher
          label="Noise Speed X"
          v-model:value="directionX"
          :min="0"
          :max="2"
          :stepSize="0.1"
        />
        <variableWatcher
          label="Noise Speed Y"
          v-model:value="directionY"
          :min="0"
          :max="2"
          :stepSize="0.1"
        />
        <variableWatcher
          label="Noise Directional Speed"
          v-model:value="directionTime"
          :min="0"
          :max="2"
          :stepSize="0.1"
        />
      </ToggleSection>
      <ToggleSection title="Base">
        <variableWatcher
          label="Height"
          v-model:value="baseHeight"
          :min="0"
          :max="800"
        />
        <variableWatcher
          label="Countour Line Count"
          v-model:value="lineCount"
          :min="1"
          :max="20"
        />
        <variableWatcher
          label="Draw Base"
          v-model:value="baseEnable"
          inputType="boolean"
        />
        <variableWatcher
          label="Draw Contour Lines"
          v-model:value="contours"
          inputType="boolean"
        />
        <variableWatcher
          label="Base Vertical Edges"
          v-model:value="edgeLine"
          inputType="boolean"
        />
        <variableWatcher
          label="Straight Base"
          v-model:value="straigthBase"
          inputType="boolean"
        />
      </ToggleSection>
    </ToggleSection>
    <ToggleSection title="Scene Settings">
      <variableWatcher
        label="Vertical Angle"
        v-model:value="cameraAngle"
        :min="0"
        :max="90"
      />
      <variableWatcher
        label="Grid Line Color"
        v-model:value="gridLineColor"
        v-model="gridLineColor"
        inputType="color-picker"
      />
      <variableWatcher
        label="Background Color"
        v-model:value="gridBackgroundColor"
        v-model="gridBackgroundColor"
        inputType="color-picker"
      />
      <variableWatcher
        label="Line Thickness"
        v-model:value="gridLineWidth"
        :min="0.1"
        :max="5"
        :stepSize="0.1"
      />
    </ToggleSection>
  </div>
</template>

<script>
import { SVG } from "@svgdotjs/svg.js";
import { Meadow } from "./js/meadow.js";
import { ref } from "vue";

import variableWatcher from "../varGui/variableWatcher.vue";
import ToggleSection from "../varGui/ToggleSection.vue";
import customButton from "../varGui/customButton.vue";

export default {
  name: "MeadowAnimated",
  components: {
    variableWatcher,
    ToggleSection,
    customButton,
  },
  setup() {
    const arraySizeX = ref(6);
    const arraySizeY = ref(16);
    const width = ref(80);
    const height = ref(80);
    const noiseSize = ref(5);
    const amplifier = ref(150);
    const speed = ref(1);
    const directionX = ref(0.5);
    const directionY = ref(0);
    const directionTime = ref(0);
    const baseHeight = ref(100);
    const lineCount = ref(10);
    const baseEnable = ref(true);
    const contours = ref(true);
    const verticalEdges = ref(true);
    const edgeLine = ref(true);
    const straigthBase = ref(true);
    const cameraAngle = ref(45);
    const gridLineColor = ref("#000000");
    const gridBackgroundColor = ref("#FFF");
    const gridLineWidth = ref(1);

    return {
      arraySizeX,
      arraySizeY,
      width,
      height,
      noiseSize,
      amplifier,
      speed,
      directionX,
      directionY,
      directionTime,
      baseHeight,
      lineCount,
      baseEnable,
      contours,
      verticalEdges,
      edgeLine,
      straigthBase,
      cameraAngle,
      gridLineColor,
      gridBackgroundColor,
      gridLineWidth,
    };
  },
  data() {
    return {
      scenarios: [
        {
          name: "Single Tree",
          twigLength: 3,
          deviationAngle: 1.8,
          branchSize: 300,
          twigColor: "#0000FF",
          bgColor: "#fff4d9",
          circle: true,
          middleSeed: true,
          seeds: 1,
          verticalMargin: 60,
          horizontalMargin: 60,
        },
        {
          name: "Single Sqaure",
          twigLength: 3,
          deviationAngle: 1.8,
          branchSize: 300,
          twigColor: "#0000FF",
          bgColor: "#fff4d9",
          circle: false,
          middleSeed: false,
          seeds: 1,
          verticalMargin: 60,
          horizontalMargin: 200,
        },
        {
          name: "Shy Canopies",
          twigLength: 2.5,
          deviationAngle: 1.4,
          branchSize: 300,
          twigColor: "#0000FF",
          bgColor: "#fff4d9",
          circle: false,
          middleSeed: false,
          seeds: 8,
          verticalMargin: 60,
          horizontalMargin: 60,
        },
        {
          name: "Imperfect Circle",
          twigLength: 3.2,
          deviationAngle: 1.3,
          branchSize: 90,
          twigColor: "#0000FF",
          bgColor: "#fff4d9",
          circle: true,
          middleSeed: false,
          seeds: 1,
          verticalMargin: 60,
          horizontalMargin: 60,
        },
        {
          name: "Small Blobs",
          twigLength: 3.0,
          deviationAngle: 1.8,
          branchSize: 34,
          twigColor: "#0000FF",
          bgColor: "#fff4d9",
          circle: false,
          middleSeed: false,
          seeds: 15,
          verticalMargin: 60,
          horizontalMargin: 60,
        },
        {
          name: "Pillar of Trees",
          twigLength: 3.0,
          deviationAngle: 1.8,
          branchSize: 60,
          twigColor: "#0000FF",
          bgColor: "#fff4d9",
          circle: false,
          middleSeed: false,
          seeds: 10,
          verticalMargin: 60,
          horizontalMargin: 320,
        },
      ],
      selectedScenario: null,
    };
  },
  watch: {
    arraySizeX(value) {
      this.meadow.settings.arraySize.x = value;
      this.$forceUpdate(); // Force a re-render
    },
    arraySizeY(value) {
      this.meadow.settings.arraySize.y = value;
      this.$forceUpdate(); // Force a re-render
    },
    width(value) {
      this.meadow.settings.arraySize.width = value;
      this.$forceUpdate(); // Force a re-render
    },
    height(value) {
      this.meadow.settings.arraySize.height = value;
      this.$forceUpdate(); // Force a re-render
    },
    noiseSize(value) {
      this.meadow.settings.noise.size = value;
      this.$forceUpdate(); // Force a re-render
    },
    amplifier(value) {
      this.meadow.settings.noise.amplifier = value;
      this.$forceUpdate(); // Force a re-render
    },
    speed(value) {
      this.meadow.settings.noise.speed = value;
      this.$forceUpdate(); // Force a re-render
    },
    directionX(value) {
      this.meadow.settings.noise.directionX = value;
      this.$forceUpdate(); // Force a re-render
    },
    directionY(value) {
      this.meadow.settings.noise.directionY = value;
      this.$forceUpdate(); // Force a re-render
    },
    directionTime(value) {
      this.meadow.settings.noise.directionTime = value;
      this.$forceUpdate(); // Force a re-render
    },
    baseHeight(value) {
      this.meadow.settings.base.height = value;
      this.$forceUpdate(); // Force a re-render
    },
    lineCount(value) {
      this.meadow.settings.base.lineCount = value;
      this.$forceUpdate(); // Force a re-render
    },
    baseEnable(value) {
      this.meadow.settings.base.enable = value;
      this.$forceUpdate(); // Force a re-render
    },
    contours(value) {
      this.meadow.settings.base.contours = value;
      this.$forceUpdate(); // Force a re-render
    },
    edgeLine(value) {
      this.meadow.settings.base.edgeLine = value;
      this.$forceUpdate(); // Force a re-render
    },
    straigthBase(value) {
      this.meadow.settings.base.straigthBase = value;
      this.$forceUpdate(); // Force a re-render
    },
    cameraAngle(value) {
      this.meadow.settings.camera.angle = value;
      this.$forceUpdate(); // Force a re-render
    },
    gridLineColor(value) {
      this.meadow.settings.gridVisuals.lineColor = value;
      this.$forceUpdate(); // Force a re-render
    },
    gridBackgroundColor(value) {
      this.meadow.settings.gridVisuals.backgroundColor = value;
      this.$forceUpdate(); // Force a re-render
    },
    gridLineWidth(value) {
      this.meadow.settings.gridVisuals.width = value;
      this.$forceUpdate(); // Force a re-render
    },
  },
  mounted() {
    const canvasElement = this.$refs.canvas;
    let width = canvasElement.clientWidth;
    let height = canvasElement.clientHeight;

    const draw = SVG().addTo(canvasElement).size(width, height);

    this.meadow = new Meadow(draw, width, height);

    const animate = () => {
      this.meadow.updateAndDraw();
      requestAnimationFrame(animate);
    };

    animate();
  },
  methods: {
    download() {
      // Do something when header is clicked
      this.meadow.download();
    },
    loadScenario() {
      if (this.selectedScenario) {
        const scenario = this.selectedScenario;
        for (const prop in scenario) {
          if (Object.prototype.hasOwnProperty.call(scenario, prop)) {
            this[prop] = scenario[prop];
          }
        }
        this.$nextTick(() => {
          this.regrow();
          this.$forceUpdate();
        });
      }
    },
  },
};
</script>

<style scoped>
h1 {
  font-size: 1.2rem;
  text-align: left;
}
</style>
