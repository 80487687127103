<!-- ExternalContent.vue -->

<template>
  <div id="mainBlock">
    <iframe
      src="/Droplet/index.html"
      frameborder="0"
      width="100%"
      height="100%"
    ></iframe>
  </div>
</template>

<script>
export default {
  name: "DropletAnimated",
  // You can add any component-specific options here
};
</script>

<style scoped>
#mainBlock {
  width: 100vw;
  height: 90vh;
}
</style>
