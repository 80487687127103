<template>
  <div>
    <div class="tagInstruction">Filter projects by theme</div>
    <div class="tag-filter">
      <span
        v-for="tag in availableTags"
        :key="tag"
        :class="getClassForTag(tag)"
      >
        <Checkbox
          type="checkbox"
          v-model="selectedTags"
          :value="tag"
          :inputId="tag"
          class="checker"
        />
        <label :for="tag" class="tagLabel"> {{ tag }} </label>
      </span>
    </div>
    <div class="project-grid">
      <ProjectTile
        v-for="project in this.projects"
        :key="project.id"
        :title="project.title"
        :description="project.description"
        :link="project.link"
        :tags="project.tags"
        :inactive="isInactive(project.tags)"
        :selectedTags="this.selectedTags"
      />
    </div>
  </div>
</template>

<script>
import ProjectTile from "./ProjectTile.vue";
import { projectsArray } from "./Projects.js";
import Checkbox from "primevue/checkbox";

export default {
  components: {
    ProjectTile,
    Checkbox,
  },
  data() {
    return {
      selectedTags: [],
      projects: projectsArray,
    };
  },
  computed: {
    availableTags() {
      let tags = [];
      this.projects.forEach((project) => {
        tags = tags.concat(project.tags);
      });
      return Array.from(new Set(tags));
    },
  },
  methods: {
    isInactive(tags) {
      return (
        this.selectedTags.length > 0 &&
        !this.selectedTags.every((tag) => tags.includes(tag))
      );
    },
    getClassForTag(tag) {
      return {
        tagButton: this.selectedTags.includes(tag),
        notSelectedTagButton: !this.selectedTags.includes(tag),
      };
    },
  },
};
</script>

<style scoped>
.tagInstruction {
  margin-left: auto;
  margin-right: auto;
  max-width: 1000px;
  text-align: left;
  font-size: 0.5rem;
  margin-bottom: 5px;
  padding-left: 5px;
}
.tag-filter {
  margin-left: auto;
  margin-right: auto;
  max-width: 1000px;
  margin-bottom: 10px;
  display: flex;
  justify-content: left;
  padding-right: 10px;
}

.checker {
  visibility: hidden;
  position: absolute;
}

.tagButton {
  font-size: 0.7rem;
  padding: 2px 6px;
  margin-left: 5px;
  background-color: var(--button-selected-background);
  color: var(--text-color);
  border: 1px solid var(--line-color);
  cursor: pointer;
}

.notSelectedTagButton {
  font-size: 0.7rem;
  padding: 2px 6px;
  margin-left: 5px;
  background-color: var(--background-color);
  color: var(--text-color);
  border: 1px solid var(--line-color);
  /* border-radius: 4px; */
  cursor: pointer;
}

.notSelectedTagButton:hover {
  background-color: var(--button-hover-background);
}

.tagLabel {
  cursor: pointer;
}

.project-grid {
  margin-left: auto;
  margin-right: auto;
  max-width: 1000px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-auto-rows: 1fr;
}

.project-grid::before {
  content: "";
  width: 0;
  padding-bottom: 100%;
  grid-row: 1 / 1;
  grid-column: 1 / 1;
}

.project-grid > *:first-child {
  grid-row: 1 / 1;
  grid-column: 1 / 1;
}
</style>
