import Victor from "victor";

export default class Boid {
  constructor(x, y, flowfield, p5) {
    this.flowfield = flowfield;
    this.points = [];

    this.position = new Victor(x, y);
    this.maxSpeed = 10;
    this.maxForce = this.flowfield.settings.boids.steeringForce;
    this.velocity = this.getTargetVelocity()
      .normalize()
      .multiplyScalar(this.maxForce);
    this.acceleration = new Victor(0, 0);
    this.p5 = p5;

    this.canvasWidth = this.flowfield.width;
    this.canvasHeight = this.flowfield.height;

    this.triesToPositionItself = 0;
    this.lastPosition = new Victor(x, y);
    this.sleep = false;
    this.getFirstPosition();
    this.drawSelf();
  }

  getFirstPosition() {
    switch (this.flowfield.settings.boidWrap) {
      case "respawnRandom":
        this.getRandomPosition();
        break;
      case "respawnEdge":
        this.getRandomPositionAlongEdge();
        break;
      case "wrapEdge":
        this.getRandomPositionAlongEdge();
        break;
      case "dontRespawnStartRandom":
        this.getRandomPositionAlongEdge();
        break;
    }
  }

  getTargetVelocity(x = this.position.x, y = this.position.y) {
    const direction = this.flowfield.lookUpVector(x, y);
    const velocityX = Math.cos(direction);
    const velocityY = Math.sin(direction);

    return new Victor(velocityX, velocityY);
  }

  follow() {
    var desired = this.getTargetVelocity();

    desired.multiplyScalar(this.maxSpeed);

    var steering = desired.subtract(this.velocity);

    const currentSteeringForce = steering.magnitude();
    if (currentSteeringForce > this.maxForce) {
      steering.normalize().multiplyScalar(this.maxForce);
    }

    this.applyForce(steering);
  }

  applyForce(force) {
    this.acceleration.add(force);
  }

  drawSelf() {
    if (!this.sleep) {
      this.lastPosition.x = this.position.x;
      this.lastPosition.y = this.position.y;
      this.follow();
      this.move();

      if (!this.flowfield.settings.gridVisuals.artMode) {
        this.drawArrowHead(this.position.x, this.position.y);
      } else {
        if (this.flowfield.settings.gridVisuals.animateLint) {
          this.drawTrailLine();
        } else {
          this.points.push([this.position.x, this.position.y]);
        }
      }
    }
  }

  drawFinalLine() {
    this.p5.push();
    var color = this.p5.color(this.flowfield.settings.gridVisuals.lineColor);
    color.setAlpha(this.flowfield.settings.gridVisuals.lineTransparancy);
    this.p5.strokeWeight(this.flowfield.settings.gridVisuals.lineThickness);
    // this.p5.translate(this.position.x, this.position.y);
    this.p5.stroke(color);
    this.p5.noFill();
    this.p5.beginShape();
    for (let i = 0; i < this.points.length; i++) {
      this.p5.vertex(this.points[i][0], this.points[i][1]);
    }
    this.p5.endShape();
    this.p5.pop();
  }

  drawArrowHead(x, y) {
    this.p5.push();
    this.p5.noStroke();
    this.p5.translate(x, y);
    this.p5.rotate(this.velocity.horizontalAngle());

    this.p5.fill("rgba(47, 47, 47, 1)");
    this.p5.triangle(0, 5, 14, 0, 0, -5);

    this.p5.pop();
  }

  drawTrailLine() {
    var color = this.p5.color(this.flowfield.settings.gridVisuals.lineColor);
    color.setAlpha(this.flowfield.settings.gridVisuals.lineTransparancy);
    this.p5.push();
    this.p5.strokeWeight(this.flowfield.settings.gridVisuals.lineThickness);
    // this.p5.translate(this.position.x, this.position.y);
    this.p5.stroke(color);
    this.p5.line(
      this.lastPosition.x,
      this.lastPosition.y,
      this.position.x,
      this.position.y
    );
    this.p5.pop();
  }

  move() {
    this.velocity.add(this.acceleration);

    // Calculate the current magnitude of the vector
    const currentVelocity = this.velocity.magnitude();

    // Scale down the vector if its length exceeds the maximum
    if (currentVelocity > this.maxSpeed) {
      this.velocity.normalize().multiplyScalar(this.maxSpeed);
    }

    this.position.add(this.velocity);
    this.acceleration.multiplyScalar(0);

    switch (this.flowfield.settings.boidWrap) {
      case "respawnRandom":
        this.dropInAroundCanvas();
        break;
      case "respawnEdge":
        this.resetAroundCanvas();
        break;
      case "wrapEdge":
        this.wrapAroundCanvas();
        break;
      case "dontRespawnStartRandom":
        this.deleteAfterEdge();
        break;
    }
  }

  getRandomPositionAlongEdge() {
    // Choose a random side (1 for top, 2 for right, 3 for bottom, 4 for left)
    var side = Math.floor(Math.random() * 4) + 1;

    // Initialize variables for the chosen position
    var x = 0;
    var y = 0;

    // Pick a random position along the chosen side
    switch (side) {
      case 1: // Top side
        x = Math.floor(Math.random() * this.canvasWidth);
        y = 0;
        this.checkDirectionCorrect(x, y);
        break;
      case 2: // Right side
        x = this.canvasWidth;
        y = Math.floor(Math.random() * this.canvasHeight);
        this.checkDirectionCorrect(x, y);
        break;
      case 3: // Bottom side
        x = Math.floor(Math.random() * this.canvasWidth);
        y = this.canvasHeight;
        this.checkDirectionCorrect(x, y);
        break;
      case 4: // Left side
        x = 0;
        y = Math.floor(Math.random() * this.canvasHeight);
        this.checkDirectionCorrect(x, y);
        break;
    }
  }

  checkDirectionCorrect(x, y) {
    var velocityAngle = this.getTargetVelocity(x, y).angleDeg();
    var positionCorrect = true;

    if (x == 0) {
      if (velocityAngle > 90 || velocityAngle < -90) {
        positionCorrect = false;
      }
    } else if (x == this.canvasWidth) {
      if (velocityAngle < 90 && velocityAngle > -90) {
        positionCorrect = false;
      }
    } else if (y == 0) {
      if (velocityAngle < 0) {
        positionCorrect = false;
      }
    } else if (y == this.canvasHeight) {
      if (velocityAngle > 0 && velocityAngle < 180) {
        positionCorrect = false;
      }
    } else {
      positionCorrect = true;
    }

    if (!positionCorrect) {
      this.triesToPositionItself++;
      if (this.triesToPositionItself > 100) {
        console.log("could not find a spot along the edge");
        this.getRandomPosition();
      }
      this.getRandomPositionAlongEdge();
    }

    if (positionCorrect) {
      this.triesToPositionItself = 0;
      this.position.x = x;
      this.position.y = y;
      this.velocity = this.getTargetVelocity()
        .normalize()
        .multiplyScalar(this.maxSpeed);
      this.resetPositionAndLine();
    }
  }

  getRandomPosition() {
    this.position.x = Math.floor(Math.random() * this.canvasWidth);
    this.position.y = Math.floor(Math.random() * this.canvasHeight);
    this.resetPositionAndLine();
    this.velocity = this.getTargetVelocity()
      .normalize()
      .multiplyScalar(this.maxSpeed);
  }

  resetPositionAndLine() {
    if (!this.flowfield.settings.gridVisuals.animateLint) {
      this.drawFinalLine();
    }
    this.lastPosition = this.position.clone();
    this.points = [];
  }

  wrapAroundCanvas() {
    if (this.position.x > this.canvasWidth) {
      this.position.x = 0;
      this.checkDirectionCorrect(this.position.x, this.position.y);
    } else if (this.position.x < 0) {
      this.position.x = this.canvasWidth;
      this.checkDirectionCorrect(this.position.x, this.position.y);
    }
    if (this.position.y > this.canvasHeight) {
      this.position.y = 0;
      this.checkDirectionCorrect(this.position.x, this.position.y);
    } else if (this.position.y < 0) {
      this.position.y = this.canvasHeight;
      this.checkDirectionCorrect(this.position.x, this.position.y);
    }
  }

  resetAroundCanvas() {
    if (this.position.x > this.canvasWidth) {
      this.getRandomPositionAlongEdge();
    } else if (this.position.x < 0) {
      this.getRandomPositionAlongEdge();
    }

    if (this.position.y > this.canvasHeight) {
      this.getRandomPositionAlongEdge();
    } else if (this.position.y < 0) {
      this.getRandomPositionAlongEdge();
    }
  }

  dropInAroundCanvas() {
    if (this.position.x > this.canvasWidth) {
      this.getRandomPosition();
    } else if (this.position.x < 0) {
      this.getRandomPosition();
    }

    if (this.position.y > this.canvasHeight) {
      this.getRandomPosition();
    } else if (this.position.y < 0) {
      this.getRandomPosition();
    }
  }

  deleteAfterEdge() {
    if (this.position.x > this.canvasWidth) {
      this.sleep = true;
    } else if (this.position.x < 0) {
      this.sleep = true;
    }

    if (this.position.y > this.canvasHeight) {
      this.sleep = true;
    } else if (this.position.y < 0) {
      this.sleep = true;
    }
  }
}
