<template>
  <NavigationBar />
  <router-view />
</template>

<script>
import NavigationBar from "@/components/NavigationBar.vue";

export default {
  components: {
    NavigationBar,
  },
};
</script>

<style>
body {
  margin: 0px;
  padding-top: 80px;
  background-color: var(--background-color);
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: var(--text-color);
  margin: 0px;
  padding: 0px;
  height: 100%;
}
</style>
